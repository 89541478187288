import { styled } from "@mui/system";
import Button from "@mui/material/Button";
import InputAdornment from "@mui/material/InputAdornment";
import type { InputAdornmentProps } from "@mui/material/InputAdornment";
import type { ButtonProps } from "@mui/material/Button";

import { ReactComponent as CardSvg } from "svgs/card.svg";
import { ReactComponent as MasterCardSvgLogo } from "svgs/mastercard.svg";
import { ReactComponent as VisaSvgLogo } from "svgs/visa.svg";

export const CardSvgStyled = styled(CardSvg)`
  overflow: hidden;
  width: 100%;
  transform: scale(1.1);
`;

export const MasterCardSvgLogoStyled = styled(MasterCardSvgLogo)`
  width: 31px;
  height: 21px;
`;

export const VisaSvgLogoStyled = styled(VisaSvgLogo)`
  width: 31px;
  height: 21px;
`;

export const InfoEndInputAdornment = styled(
  InputAdornment
)<InputAdornmentProps>`
  svg.MuiSvgIcon-root {
    color: ${({ theme }) => theme.palette.gray.secondary};
  }
`;

export const SubmitButtonStyled = styled(Button, {
  shouldForwardProp: (props) => props !== "isEditMode",
})<ButtonProps & { isEditMode: boolean }>`
  &.MuiButton-root {
    background-color: ${({ theme }) => theme.palette.orange.primary};
    border-radius: 102px;
    color: white;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: -0.24px;
    line-height: 20px;
    margin-top: 32px;
    padding-bottom: 14px;
    padding-top: 14px;
    text-transform: none;

    ${({ isEditMode }) =>
      isEditMode && {
        bottom: 42,
        left: 0,
        margin: "auto",
        maxWidth: "calc(767px - 32px)",
        paddingLeft: 0,
        position: "fixed",
        right: 0,
        width: "calc(100% - 32px)",
      }}

    &.Mui-disabled {
      opacity: 0.7;
    }
  }
`;
