import { useState } from "react";
import * as Sentry from "@sentry/react";
import { useMutation } from "react-query";
import { format } from "date-fns";

import * as apiService from "services/api";
import useCapabilityUrlInfoQuery from "./useCapabilityUrlInfoQuery";
import useIpAddressQuery from "./useIpAddressQuery";
import usePublicKeyQuery from "./usePublicKeyQuery";
import { segmentAnalyticsTrack } from "services/segmentAnalytics";
import useUserInfoQueryByPhoneNumberQuery from "./useUserInfoQueryByPhoneNumberQuery";
import { posthogReset } from "services/posthog";
import useFingerPrintData from "./useFingerPrintData";

const useGenerateCardPayment = () => {
  const [asyncStatus, setAsyncStatus] = useState<AsyncStatus>("IDLE");
  const [error, setError] = useState<any>();
  const [errorCode, setErrorCode] = useState<string | undefined>();
  const { data: capabilityUrlInfoData } = useCapabilityUrlInfoQuery();
  const { data: publicKeyData } = usePublicKeyQuery();
  const { data: ipAddressData } = useIpAddressQuery();
  const polancoNewUserPaymentMutation = useMutation(apiService.newUserPayment);
  const userInfo = useUserInfoQueryByPhoneNumberQuery();
  const { getBase64Session } = useFingerPrintData();

  const execPayment = async (formData: NewUserCardPaymentValue) => {
    try {
      setAsyncStatus("LOADING");

      if (typeof publicKeyData?.key === "undefined") {
        return;
      }

      const [firstName, middleName, lastName] = [
        formData.firstName ?? "",
        formData.middleName ?? "",
        formData.lastName ?? "",
      ];

      const sessionData = await getBase64Session();

      const { data: responseData } =
        await polancoNewUserPaymentMutation.mutateAsync({
          idempotency_key: formData.capabilityId,
          user_id: userInfo.data?.id,
          user_data: {
            first_name: firstName,
            middle_name: middleName,
            last_name: lastName,
            birthdate:
              formData.birthDate && format(formData.birthDate, "y-MM-dd"),
            email: formData.email,
          },
          user_type: "INDIVIDUAL",
          payment_instrument_data: {
            card_token: formData.cardToken,
          },
          payment_instrument: "CARD",
          payment_gateway: formData.paymentGateway,
          payment_onboarding_level: "NEW_USER",
          billing_details: {
            name: formData.cardHolderName,
            city: formData.city,
            country: "US",
            line1: formData.address,
            number: formData.addressNumber,
            district: formData.state,
            postal_code: formData.postalCode,
          },
          anti_fraud_data_points: {
            ip_address: ipAddressData,
            session_id: sessionData,
          },
          verification: "cvv",
          capability_url_id: formData.capabilityId,
        });

      const redirect: string = responseData.redirect ?? "";

      if (redirect !== "") {
        setTimeout(() => {
          posthogReset();
          window.location.replace(redirect);
        }, 500);
      } else if (responseData.error) {
        // TODO(Mauricio): Refactor this code to communicate this through a custom error
        setErrorCode(responseData.error?.error_code);
        throw new Error(
          `ERROR ON PAYMENT: ${responseData.error?.error_code} -  ${responseData.error?.error_summary}`
        );
      } else {
        setAsyncStatus("SUCCESS");
        segmentAnalyticsTrack("card_payment_succeeded", {
          userId: userInfo.data?.id,
          email: formData.email,
          firstName: formData.firstName,
          middleName: formData.middleName,
          lastName: formData.lastName,
          birthDate: `${formData.birthDate?.getFullYear()}${formData.birthDate?.getUTCMonth()}${formData.birthDate?.getUTCDay()}`,
          address: formData.address,
          addressNumber: formData.addressNumber,
          city: formData.city,
          postalCode: formData.postalCode,
          state: formData.state,
          amountToSend: capabilityUrlInfoData?.amount_to_send,
        });
      }
    } catch (e) {
      Sentry.captureException(e);
      setAsyncStatus("ERROR");
      setError(e);
      segmentAnalyticsTrack("card_payment_error", {
        email: formData.email,
        firstName: formData.firstName,
        middleName: formData.middleName,
        lastName: formData.lastName,
        birthDate: `${formData.birthDate?.getFullYear()}${formData.birthDate?.getUTCMonth()}${formData.birthDate?.getUTCDay()}`,
        address: formData.address,
        addressNumber: formData.addressNumber,
        city: formData.city,
        postalCode: formData.postalCode,
        state: formData.state,
        error: e,
      });
    }
  };

  return {
    execPayment,
    error,
    errorCode,
    isLoading: asyncStatus === "LOADING",
    isSuccess: asyncStatus === "SUCCESS",
    isError: asyncStatus === "ERROR",
    asyncStatus: asyncStatus,
  };
};

export default useGenerateCardPayment;
