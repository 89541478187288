import * as Yup from "yup";
import valid from "card-validator";

export const validationSchema = Yup.object().shape({
  firstName: Yup.string().required("Nombre requerido."),
  middleName: Yup.string(),
  lastName: Yup.string().required("Apellido requerido."),
  birthDate: Yup.date().required("Fecha nacimiento requerida."),
  cardHolderName: Yup.string().test(
    "card-holder-name",
    "Nombre del titular inválido. Ingresa nombre y apellido.",
    (value) => {
      const cardHolderNameValue = value?.trim() ?? "";
      return (
        valid.cardholderName(cardHolderNameValue).isValid &&
        cardHolderNameValue?.split(" ").length >= 2
      );
    }
  ),
  cardNumber: Yup.string(),
  cardExpirationDate: Yup.string(),
  cardCvv: Yup.string(),
  cardToken: Yup.string(),
  email: Yup.string()
    .email("Debe ser un correo válido.")
    .required("Email requerido."),
  address: Yup.string().required("Dirección requerida."),
  addressNumber: Yup.string(),
  city: Yup.string().required("Ciudad requerida."),
  postalCode: Yup.string().required("Código postal requerido."),
  state: Yup.string().required("Estado requerido."),
  capabilityId: Yup.string().nullable(),
  paymentType: Yup.string(),
  cardExpirationMonth: Yup.string(),
  cardExpirationYear: Yup.string(),
  cardExpiresOn: Yup.string(),
  cardScheme: Yup.string(),
  cardLastFourDigits: Yup.string(),
  cardBin: Yup.string(),
  cardType: Yup.string(),
  cardCategory: Yup.string(),
  cardIssuerCountry: Yup.string(),
  cardProductId: Yup.string(),
  cardProductType: Yup.string(),
  ckoValidation: Yup.object().shape({
    cardNumber: Yup.boolean().required().isTrue(),
    expiryDate: Yup.boolean().required().isTrue(),
    cvv: Yup.boolean().required().isTrue(),
  }),
  paymentGateway: Yup.string(),
});
