import { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import * as Sentry from "@sentry/react";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import NumberFormat from "react-number-format";
import { useFormik } from "formik";
import { Frames } from "frames-react";

import PageLayout from "components/PageLayout";
import Select from "components/Select";
import TextField from "components/TextField";
import Typography from "components/Typography";
import statesData from "data/states.json";
import mexicanStatesData from "data/mexican_states.json";

import {
  validationAddCardSchema,
  validationEditCardSchema,
} from "./CardScreen.schema";
import {
  CardSvgStyled,
  MasterCardSvgLogoStyled,
  SubmitButtonStyled,
  VisaSvgLogoStyled,
} from "./CardScreen.styled";
import { CardScreenProps } from "./CardScreen.types";
import { PaymentGateway } from "utils/constants";
import { resetCKOValidation } from "utils/ckoFrameValidator";
import CKOCardScreen from "components/CKOCardScreen";

const CardScreen = ({
  cardFormData,
  cardDisplayData,
  variant,
  onSubmitting,
}: CardScreenProps) => {
  const [formSubmitStatus, setFormSubmitStatus] = useState<AsyncStatus>("IDLE");

  const [states] = useState(Object.values({ ...statesData }));
  const [mexicanStates] = useState(Object.values({ ...mexicanStatesData }));
  const [disableSubmit, setDisableSubmit] = useState(true);
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const lt = searchParams.get("lt");
  const location = useLocation();
  const navigate = useNavigate();
  const { pathname, search } = location;
  const isAddVariant = variant === "add";
  const isEditVariant = variant === "edit";
  const pageName = `${isAddVariant ? "Agregar" : "Editar"} tarjeta`;
  const isFormSubmitted =
    formSubmitStatus !== "IDLE" && formSubmitStatus !== "ERROR";
  const validationSchema = isAddVariant
    ? validationAddCardSchema
    : validationEditCardSchema;
  const formik = useFormik<Partial<NewUserCardPaymentValue>>({
    validationSchema,
    initialValues: cardFormData,
    onSubmit: async (values) => {
      try {
        setFormSubmitStatus("LOADING");

        const cardTokenized = await Frames.submitCard();
        values.cardToken = cardTokenized.token;
        values.paymentType = cardTokenized.type;
        values.cardExpirationMonth = cardTokenized.expiry_month;
        values.cardExpirationYear = cardTokenized.expiry_year;
        values.cardExpiresOn = cardTokenized.expires_on;
        values.cardScheme = cardTokenized.scheme;
        values.cardLastFourDigits = cardTokenized.last4;
        values.cardBin = cardTokenized.bin;
        values.cardType = cardTokenized.card_type;
        values.cardCategory = cardTokenized.card_category;
        values.cardIssuerCountry = cardTokenized.issuer_country;
        values.cardProductId = cardTokenized.product_id;
        values.cardProductType = cardTokenized.product_type;
        values.capabilityId = searchParams.get("id");
        values.paymentGateway = PaymentGateway.CHECKOUT;

        await onSubmitting(await validationSchema.validate(values));
        setFormSubmitStatus("SUCCESS");
      } catch (e) {
        Sentry.captureException(e);
        console.error(e);
        setFormSubmitStatus("ERROR");
      }
    },
  });

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    setDisableSubmit(
      !(formik.isValid && formik.dirty) ||
        formik.isSubmitting ||
        isFormSubmitted
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.dirty, formik.isSubmitting, formik.isValid, isFormSubmitted]);

  useEffect(() => {
    if (formSubmitStatus !== "SUCCESS") {
      return;
    }

    navigate(`/?id=${id}&lt=${lt}`);
    setFormSubmitStatus("IDLE");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formSubmitStatus]);

  const maskCardNumbers = useMemo(() => {
    if (typeof formik.values?.cardNumber === "undefined") {
      return "";
    }

    return formik.values.cardNumber.replace(/(\d{4}(?!\s))/g, "$1 ");
  }, [formik.values.cardNumber]);

  const cardLogo = useMemo(() => {
    if (typeof formik.values.cardNumber === "undefined") {
      if (typeof cardDisplayData?.network === "undefined") {
        return null;
      }

      if (cardDisplayData?.network === "VISA") {
        return <VisaSvgLogoStyled />;
      }

      if (cardDisplayData?.network === "MASTERCARD") {
        return <MasterCardSvgLogoStyled />;
      }

      return null;
    }

    if (/^4[0-9]{6,}$/.test(formik.values.cardNumber)) {
      return <VisaSvgLogoStyled />;
    }

    if (
      /^5[1-5][0-9]{5,}|222[1-9][0-9]{3,}|22[3-9][0-9]{4,}|2[3-6][0-9]{5,}|27[01][0-9]{4,}|2720[0-9]{3,}$/.test(
        formik.values.cardNumber
      )
    ) {
      return <MasterCardSvgLogoStyled />;
    }

    return null;
  }, [formik.values.cardNumber, cardDisplayData?.network]);

  const validateStateOnCKO = (stateAbbr: string) => {
    formik.setFieldValue("paymentGateway", PaymentGateway.CHECKOUT);
    setDisableSubmit(true);
    formik.setFieldValue("ckoValidation", resetCKOValidation());
  };
  const useStyle = { fontSize: "20px", borderBottom: `1px solid` };
  return (
    <PageLayout
      pageName={pageName}
      showBackButtonNav
      routesNav={{
        prevRoute: {
          pathname,
          search,
        },
      }}
    >
      <Box
        sx={{
          maxHeight: 275,
          maxWidth: 380,
          mx: "auto",
          overflow: "hidden",
          position: "sticky",
        }}
      >
        <CardSvgStyled />
        <Grid
          container
          flexDirection="column"
          sx={{ position: "absolute", left: 32, top: 144.5 }}
        >
          <Grid item>
            <Typography
              sx={{
                color: "white",
                fontFamily: "Gilroy",
                fontSize: 22,
                fontWeight: 500,
                letterSpacing: 1,
                lineHeight: 0.9,
              }}
            >
              {isEditVariant ? (
                <>#### #### #### {cardDisplayData?.lastFourDigits ?? "####"}</>
              ) : (
                maskCardNumbers
              )}
            </Typography>
          </Grid>
          {formik.values.cardHolderName && (
            <Grid item>
              <Typography
                sx={{
                  color: "rgba(255, 255, 255, 0.5)",
                  fontFamily: "Gilroy",
                  fontSize: 10,
                  fontWeight: 600,
                  letterSpacing: 2,
                  lineHeight: 2,
                  mt: 1.75,
                }}
              >
                {formik.values.cardHolderName}
              </Typography>
            </Grid>
          )}
        </Grid>
        <Box sx={{ position: "absolute", right: 22, bottom: 67 }}>
          {cardLogo}
        </Box>
      </Box>
      <Grid item container spacing="24" direction="column">
        {isAddVariant && (
          <>
            <Grid item container spacing="24" direction="column">
              <Grid item alignSelf="start">
                <Typography variant="subtitle1">
                  Direccion Asociada a la Tarjeta
                </Typography>
              </Grid>
              <Grid item>
                <TextField
                  label="Correo electrónico"
                  type="email"
                  name="email"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                  error={Boolean(formik.errors.email && formik.touched.email)}
                  helperText={formik.touched.email && formik.errors.email}
                />
              </Grid>
              <Grid item>
                <TextField
                  label="Dirección"
                  name="address"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.address}
                  error={Boolean(
                    formik.errors.address && formik.touched.address
                  )}
                  helperText={formik.touched.address && formik.errors.address}
                />
              </Grid>
              <Grid item>
                <TextField
                  label="Número de Apartamento (Opcional)"
                  name="addressNumber"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.addressNumber}
                  error={Boolean(
                    formik.errors.addressNumber && formik.touched.addressNumber
                  )}
                />
              </Grid>
              <Grid item container spacing="17">
                <Grid item sx={{ flex: 1 }}>
                  <TextField
                    label="Ciudad"
                    name="city"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.city}
                    error={Boolean(formik.errors.city && formik.touched.city)}
                    helperText={formik.touched.city && formik.errors.city}
                  />
                </Grid>
                <Grid item sx={{ flex: 1 }}>
                  <NumberFormat
                    customInput={TextField}
                    format="#####"
                    label="Codigo Postal"
                    name="postalCode"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    type="tel"
                    value={formik.values.postalCode}
                    error={Boolean(
                      formik.errors.postalCode && formik.touched.postalCode
                    )}
                    helperText={
                      formik.touched.postalCode && formik.errors.postalCode
                    }
                  />
                </Grid>
              </Grid>
              <Grid item>
                <Select
                  id="state"
                  label="Estado"
                  labelId="state-label"
                  name="state"
                  onChange={(e) => {
                    formik.setFieldValue("state", e.target.value);
                    validateStateOnCKO(e.target.value as string);
                  }}
                  value={formik.values.state}
                >
                  <MenuItem disabled={true} style={useStyle}>
                    United States
                  </MenuItem>
                  {states.map(({ name, abbreviation }) => (
                    <MenuItem key={name} value={abbreviation}>
                      {name}
                    </MenuItem>
                  ))}
                  <MenuItem divider={true} disabled={true} style={useStyle}>
                    Mexico
                  </MenuItem>
                  {mexicanStates.map(({ name, abbreviation }) => (
                    <MenuItem key={name} value={abbreviation}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
            </Grid>
            <CKOCardScreen formik={formik}></CKOCardScreen>
          </>
        )}
        <Grid item>
          <SubmitButtonStyled
            fullWidth
            disabled={disableSubmit}
            isEditMode={isEditVariant}
            onClick={() => {
              formik.handleSubmit();
            }}
          >
            {isFormSubmitted && (
              <CircularProgress
                color="inherit"
                size={20}
                thickness={5}
                sx={{ mr: 1 }}
              />
            )}
            Guardar
          </SubmitButtonStyled>
        </Grid>
      </Grid>
    </PageLayout>
  );
};

export default CardScreen;
