import { useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import type { PropsWithChildren, FC } from "react";

import { apiHttpClient } from "services/api";

const Auth: FC<PropsWithChildren<{}>> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [searchParams] = useSearchParams();
  const prevCapabilityId = useRef<string>();
  const unSubscriberRequestInterceptor = useRef<() => void>();

  useEffect(() => {
    setIsAuthenticated(false);

    const capabilityId = searchParams.get("id");

    if (!capabilityId) {
      return;
    }

    if (capabilityId !== prevCapabilityId.current) {
      unSubscriberRequestInterceptor.current?.();
    }

    prevCapabilityId.current = capabilityId;
    unSubscriberRequestInterceptor.current =
      apiHttpClient.setAuthHeader(capabilityId);
    setIsAuthenticated(true);
  }, [searchParams]);

  if (!isAuthenticated) {
    return null;
  }

  return <>{children}</>;
};

export default Auth;
