import { useQuery } from "react-query";

import { getIpAddress } from "queries";
import queriesKeys from "queriesKeys";

const useIpAddressQuery = () => {
  return useQuery(queriesKeys.listIpAddress(), getIpAddress);
};

export default useIpAddressQuery;
