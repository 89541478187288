export const validateCKOElement = (ckoValidation: CKOValidation, e: any) => {
  if (e.element === "card-number") {
    ckoValidation.cardNumber = e.isValid;
  } else if (e.element === "expiry-date") {
    ckoValidation.expiryDate = e.isValid;
  } else if (e.element === "cvv") {
    ckoValidation.cvv = e.isValid;
  }
  return ckoValidation;
};

export const isCKODataValid = (ckoValidation: CKOValidation) => {
  return (
    ckoValidation.cardNumber && ckoValidation.expiryDate && ckoValidation.cvv
  );
};

export const resetCKOValidation = (): CKOValidation => {
  return {
    cardNumber: false,
    expiryDate: false,
    cvv: false,
  };
};
