import { PaymentGateway } from "./constants";

export const initValuesForNewCardPayment = {
  firstName: "",
  middleName: "",
  lastName: "",
  birthDate: null,
  paymentType: "",
  cardHolderName: "",
  cardNumber: "",
  cardExpirationDate: "",
  cardExpirationMonth: "",
  cardExpirationYear: "",
  cardCvv: "",
  cardToken: "",
  cardExpiresOn: "",
  cardScheme: "",
  cardLastFourDigits: "",
  cardBin: "",
  cardType: "",
  cardCategory: "",
  cardIssuerCountry: "",
  cardProductId: "",
  cardProductType: "",
  paymentGateway: PaymentGateway.CHECKOUT,
  email: "",
  address: "",
  addressNumber: "",
  city: "",
  postalCode: "",
  state: "",
  capabilityId: "",
  ckoValidation: {
    cardNumber: false,
    expiryDate: false,
    cvv: false,
  },
};
