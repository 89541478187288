import FormControl from "@mui/material/FormControl";
import { styled } from "@mui/system";
import type { FormControlProps } from "@mui/material/FormControl";

export const FormControlStyled = styled(FormControl)<FormControlProps>`
  .MuiInputLabel-root.MuiInputLabel-formControl {
    color: ${({ theme }) => theme.palette.blue.dark};
    font-family: "Gilroy";
    font-size: 10px;
    font-weight: 600;
    letter-spacing: 2px;
    line-height: 20px;
    text-transform: uppercase;
  }

  .MuiOutlinedInput-root.MuiInputBase-root {
    border-radius: 12px;

    .MuiOutlinedInput-input.MuiInputBase-input {
      color: ${({ theme }) => theme.palette.blue.yankees};
      font-family: "Gilroy";
      font-size: 19px;
      font-weight: 400;
      letter-spacing: 1px;
      line-height: 20px;
    }

    &.Mui-focused {
      .MuiOutlinedInput-notchedOutline {
        border-color: ${({ theme }) => theme.palette.orange.primary};
      }
    }

    fieldset {
      border-width: 1px;
      border-color: ${({ theme }) => theme.palette.blue.dark};
    }
  }
`;
