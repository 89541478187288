import { useQuery } from "react-query";

import { getLinkToken } from "queries";
import queriesKeys from "queriesKeys";
import useUserInfoQueryByPhoneNumberQuery from "./useUserInfoQueryByPhoneNumberQuery";

const useLinkToken = () => {
  const userInfo = useUserInfoQueryByPhoneNumberQuery();

  return useQuery(
    queriesKeys.listLinkToken(userInfo.data?.id ?? ""),
    getLinkToken,
    {
      cacheTime: 0,
      enabled: !!userInfo.data?.id,
    }
  );
};

export default useLinkToken;
