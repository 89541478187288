import type { QueryFunctionContext } from "react-query";
import * as Sentry from "@sentry/react";

import queriesKeys from "queriesKeys";
import * as apiService from "services/api";
import * as ipAddressService from "services/ipAddress";

export const getCapabilityUrlInfo = async () => {
  return (await apiService.getCapabilityUrlInfo()).data;
};

export const getFallbackIpAddressInfo = async () => {
  return (await ipAddressService.getFallbackIpAddressInfo()).data;
};

export const getIpAddressInfo = async () => {
  return (await ipAddressService.getIpAddressInfo()).data;
};

export const getIpAddress = async () => {
  try {
    const responseData = await getIpAddressInfo();

    if (typeof responseData.error === "undefined" && responseData.ip !== "") {
      return responseData.ip;
    }

    return (await getFallbackIpAddressInfo()).ip;
  } catch (e) {
    Sentry.captureException(e);
    Sentry.captureMessage("Error while getting IP address");
    return "";
  }
};

export const getPublicKey = async () => {
  return (await apiService.getPublicKey()).data;
};

export const getUserInfoByPhoneNumber = async ({
  queryKey: [{ phoneNumber, countryCode }],
}: QueryFunctionContext<
  ReturnType<typeof queriesKeys["listUserInfoByPhoneNumber"]>
>) => {
  return (await apiService.getUserByPhoneNumber(phoneNumber, countryCode)).data;
};

export const getUserInfoById = async ({
  queryKey: [{ id }],
}: QueryFunctionContext<
  ReturnType<typeof queriesKeys["listUserInfoById"]>
>) => {
  return (await apiService.getUserById(id)).data;
};

export const getCardsByUserId = async ({
  queryKey: [{ userId }],
}: QueryFunctionContext<
  ReturnType<typeof queriesKeys["listCardsByUserId"]>
>) => {
  return (await apiService.getCardsByUserId(userId)).data;
};

export const getLinkToken = async ({
  queryKey: [{ userId }],
}: QueryFunctionContext<ReturnType<typeof queriesKeys["listLinkToken"]>>) => {
  return (await apiService.getLinkToken(userId)).data;
};

export const getBankAccountsByUserId = async ({
  queryKey: [{ userId }],
}: QueryFunctionContext<
  ReturnType<typeof queriesKeys["listBankAccountsByUserId"]>
>) => {
  return (await apiService.getBankAccountsByUserId(userId)).data;
};
