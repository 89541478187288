import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import type { FC, ReactNode } from "react";

import LoaderScreen from "components/LoaderScreen";
import useCardsByUserId from "hooks/useCardsByUserId";
import useUserInfoQueryByPhoneNumberQuery from "hooks/useUserInfoQueryByPhoneNumberQuery";
import { posthogIdentify } from "services/posthog";
import * as Sentry from "@sentry/react";

const CheckIfExistsRegisteredCardsValidator: FC<{
  children: ReactNode;
}> = ({ children }) => {
  const userInfo = useUserInfoQueryByPhoneNumberQuery();
  const cards = useCardsByUserId();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const isLoading = userInfo.isLoading || cards.isLoading;

  const isUserInfoComplete: boolean =
    userInfo.data?.first_name !== "" && !userInfo.data?.lite_user;

  useEffect(() => {
    const id = searchParams.get("id");
    const lt = searchParams.get("lt");

    if (
      id &&
      (lt === "False" || lt === null) &&
      cards.isSuccess &&
      ((typeof cards.data !== "undefined" && cards.data.length) ||
        isUserInfoComplete)
    ) {
      navigate(`/returned-user-steps?id=${id}&lt=${lt}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cards.isSuccess, userInfo]);

  if (!isLoading && (userInfo.isSuccess || !cards.isSuccess)) {
    if (userInfo.data?.id) {
      Sentry.setUser({ id: userInfo.data?.id });
      posthogIdentify(
        userInfo.data?.id,
        `${userInfo.data?.first_name} ${userInfo.data?.last_name}`,
        userInfo.data?.phone,
        userInfo.data?.phone_country_code
      );
    }
    return <>{children}</>;
  }

  return <LoaderScreen />;
};

export default CheckIfExistsRegisteredCardsValidator;
