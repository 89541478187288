import { useEffect } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";

import Typography from "components/Typography";
import { segmentAnalyticsPage } from "services/segmentAnalytics";
import { ReactComponent as SuccessIndicatorSvg } from "svgs/success-indicator.svg";
import { WhatsAppLogoStyled } from "./SuccessfulPaymentScreen.styled";

const SuccessfulPaymentScreen = () => {
  const handleOnWhatsAppRedirect = () => {
    window.location.replace("https://wa.me/+16693333549");
  };

  useEffect(() => {
    segmentAnalyticsPage("payment_succeeded_page", {});
  }, []);

  return (
    <Box
      sx={{
        width: "100vw",
        height: `100vh`,
        backgroundColor: "blue.primar",
        position: "fixed",
        top: 0,
      }}
    >
      <Grid
        container
        justifyContent="center"
        sx={{
          maxWidth: 767,
          mx: "auto",
          "& > svg:first-of-type": {
            left: 0,
            margin: "auto",
            position: "fixed",
            right: 0,
            top: "40%",
            transform: "translateY(calc(-50% - 75px))",
          },
        }}
      >
        <SuccessIndicatorSvg />
        <Box sx={{ position: "absolute", top: "50%", px: 4, maxWidth: 767 }}>
          <Typography
            sx={{
              color: "white",
              fontFamily: "Raisonne",
              fontSize: 43,
              fontWeight: 500,
              letterSpacing: "-0.24px",
              lineHeight: "125%",
            }}
          >
            ¡Tu pago fue exitoso!
          </Typography>
          <Typography
            sx={{
              color: "white",
              fontFamily: "Gilroy",
              fontWeight: 500,
              fontSize: "16px",
              lineHeight: "150%",
              letterSpacing: "1px",
            }}
          >
            Presiona el botón para volver a WhatsApp
          </Typography>
          <Button
            fullWidth
            variant="outlined"
            sx={{
              color: "white",
              mt: 5,
              borderColor: "white",
              borderRadius: 12.75,
              py: "14px",
            }}
            onClick={handleOnWhatsAppRedirect}
          >
            <WhatsAppLogoStyled />
            <Typography
              sx={{
                fontFamily: "Raisonne",
                fontWeight: 500,
                fontSize: "18px",
                lineHeight: "20px",
                letterSpacing: "-0.24px",
                textTransform: "none",
              }}
            >
              Continuar
            </Typography>
          </Button>
        </Box>
      </Grid>
    </Box>
  );
};

export default SuccessfulPaymentScreen;
