import type { TextFieldProps } from "@mui/material/TextField";

import { TextFieldStyled } from "./TextField.styled";

const TextField = (props: TextFieldProps) => {
  return (
    <TextFieldStyled
      fullWidth
      InputLabelProps={{ ...props.InputLabelProps, shrink: true }}
      {...props}
    />
  );
};

export default TextField;
