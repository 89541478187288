import { AnalyticsBrowser } from "@segment/analytics-next";

const SEGMENT_KEY = process.env.REACT_APP_SEGMENT_KEY ?? "";

export const segmentAnalytics = AnalyticsBrowser.load({
  writeKey: SEGMENT_KEY,
});

export const segmentAnalyticsIdentify = (
  user_id?: string,
  name?: string,
  phone?: string,
  phone_country_code?: string
): void => {
  segmentAnalytics.identify(user_id, {
    name: name,
    phone: phone,
    phone_country_code: phone_country_code,
  });
};

export const segmentAnalyticsTrack = (
  event_name: string,
  properties: Object
): void => {
  segmentAnalytics.track(event_name, properties);
};

export const segmentAnalyticsPage = (
  page_name: string,
  properties: Object,
  category_name?: string
): void => {
  if (category_name !== undefined) {
    segmentAnalytics.page(category_name, page_name, properties);
  } else {
    segmentAnalytics.page(page_name, properties);
  }
};
