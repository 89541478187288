import { useEffect, useState, useMemo } from "react";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import MenuItem from "@mui/material/MenuItem";
import { sub } from "date-fns";
import { useFormik } from "formik";
import NumberFormat from "react-number-format";
import { Frames } from "frames-react";

import DatePicker from "components/DatePicker";
import LoaderScreen from "components/LoaderScreen";
import LoaderScreenRequest from "components/LoaderScreenRequest";
import PageLayout from "components/PageLayout";
import ErrorScreen from "components/ErrorScreen";
import SuccessfulPaymentScreen from "components/SuccessfulPaymentScreen";
import TextField from "components/TextField";
import Select from "components/Select";
import Typography from "components/Typography";
import useCapabilityUrlInfoQuery from "hooks/useCapabilityUrlInfoQuery";
import useFingerPrintData from "hooks/useFingerPrintData";
import useGenerateCardPayment from "hooks/useGenerateCardPayment";
import useIpAddressQuery from "hooks/useIpAddressQuery";
import usePublicKeyQuery from "hooks/usePublicKeyQuery";
import { PaymentGateway, SESSION_ID } from "utils/constants";
import { validationSchema } from "./NewUserStepsPage.schema";
import {
  CardSvgStyled,
  SubmitButtonStyled,
  TermAndConditionsStyled,
} from "./NewUserStepsPaged.styled";
import { isFeatureEnabled } from "services/posthog";
import useUserInfoQueryById from "hooks/useUserInfoQueryById";
import { segmentAnalyticsPage } from "services/segmentAnalytics";
import { useSearchParams } from "react-router-dom";
import { resetCKOValidation } from "utils/ckoFrameValidator";
import CKOCardScreen from "components/CKOCardScreen";
import { initValuesForNewCardPayment } from "utils/newCardPayment";
import statesData from "data/states.json";
import mexicanStatesData from "data/mexican_states.json";

const NewUserStepsPage = () => {
  const [maxDate] = useState(
    sub(new Date(), {
      years: 18,
    })
  );
  const [searchParams] = useSearchParams();
  const [isSessionEstablished, setIsSessionEstablished] = useState(false);
  const [segmentPageViewHasBeenSent, setSegmentPageViewHasBeenSent] =
    useState(false);
  const [states] = useState(Object.values({ ...statesData }));
  const [mexicanStates] = useState(Object.values({ ...mexicanStatesData }));
  const [disableSubmit, setDisableSubmit] = useState(true);
  const capabilityUrlInfo = useCapabilityUrlInfoQuery();
  const ipAddress = useIpAddressQuery();
  const publicKey = usePublicKeyQuery();
  const fingerPrintData = useFingerPrintData();
  const { isLoading: userInfoIsLoading, data: userInfoData } =
    useUserInfoQueryById();
  // Feature flags
  const [loaderScreenRequestEnabled] = useState(
    isFeatureEnabled("loader_screen_request_new_user")
  );
  const validationSchemaGateway = validationSchema;
  const {
    execPayment,
    isLoading: isPaymentLoading,
    isSuccess: isPaymentSucceeded,
    asyncStatus: paymentCardStatus,
    errorCode: paymentErrorCode,
  } = useGenerateCardPayment();
  const formik = useFormik<NewUserCardPaymentValue>({
    validationSchema: validationSchemaGateway,
    initialValues: initValuesForNewCardPayment,
    onSubmit: async (values) => {
      const cardTokenized = await Frames.submitCard();

      values.capabilityId = searchParams.get("id");
      values.paymentGateway = PaymentGateway.CHECKOUT;
      values.cardToken = cardTokenized.token;
      values.paymentType = cardTokenized.type;
      values.cardExpirationMonth = cardTokenized.expiry_month;
      values.cardExpirationYear = cardTokenized.expiry_year;
      values.cardExpiresOn = cardTokenized.expires_on;
      values.cardScheme = cardTokenized.scheme;
      values.cardLastFourDigits = cardTokenized.last4;
      values.cardBin = cardTokenized.bin;
      values.cardType = cardTokenized.card_type;
      values.cardCategory = cardTokenized.card_category;
      values.cardIssuerCountry = cardTokenized.issuer_country;
      values.cardProductId = cardTokenized.product_id;
      values.cardProductType = cardTokenized.product_type;

      execPayment(validationSchemaGateway.cast(values));
    },
  });

  const capabilityUrlInfoData = useMemo(() => {
    const initialState = {
      amountToSend: "0.00",
      beneficiaryFullName: null,
    };
    const { data } = capabilityUrlInfo;

    if (typeof data === "undefined") {
      return initialState;
    }

    const amountToSend =
      typeof data?.amount_to_send !== "string"
        ? initialState.amountToSend
        : parseFloat(data.amount_to_send).toFixed(2);
    const beneficiaryFullName = data?.beneficiary_full_name
      ? `Para ${data.beneficiary_full_name}`
      : initialState.beneficiaryFullName;

    return {
      amountToSend,
      beneficiaryFullName,
    };
  }, [capabilityUrlInfo]);

  const isInitialDataLoaded = useMemo(() => {
    return (
      capabilityUrlInfo.isSuccess && ipAddress.isSuccess && publicKey.isSuccess
    );
  }, [capabilityUrlInfo.isSuccess, ipAddress.isSuccess, publicKey.isSuccess]);

  const hasInitialDataFetchFailed = useMemo(() => {
    return capabilityUrlInfo.isError || ipAddress.isError || publicKey.isError;
  }, [capabilityUrlInfo.isError, ipAddress.isError, publicKey.isError]);

  useEffect(() => {
    if (!isInitialDataLoaded) {
      return;
    }

    const initialize = async () => {
      await fingerPrintData.config(SESSION_ID);

      setIsSessionEstablished(true);
    };

    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInitialDataLoaded]);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    setDisableSubmit(
      !(formik.isValid && formik.dirty) ||
        formik.isSubmitting ||
        isPaymentLoading
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.dirty, formik.isSubmitting, formik.isValid, isPaymentLoading]);

  if (hasInitialDataFetchFailed) {
    return <ErrorScreen variant="invalid_link" />;
  }

  if (!isSessionEstablished) {
    return <LoaderScreen />;
  }

  if (!userInfoIsLoading && !segmentPageViewHasBeenSent) {
    segmentAnalyticsPage("new_user_page", { userId: userInfoData?.id });
    setSegmentPageViewHasBeenSent(true);
  }

  if (isPaymentSucceeded && paymentCardStatus === "SUCCESS") {
    return <SuccessfulPaymentScreen />;
  }

  if (
    !isPaymentSucceeded &&
    paymentCardStatus === "ERROR" &&
    paymentErrorCode !== undefined
  ) {
    return <ErrorScreen variant={paymentErrorCode} />;
  }

  if (!isPaymentSucceeded && paymentCardStatus === "ERROR") {
    return <ErrorScreen variant="processing_payment_failed" />;
  }

  if (loaderScreenRequestEnabled && isPaymentLoading) {
    return <LoaderScreenRequest />;
  }

  const validateStateOnCKO = (stateAbbr: string) => {
    setDisableSubmit(true);
    formik.setFieldValue("ckoValidation", resetCKOValidation());
  };
  const useStyle = { fontSize: "20px", borderBottom: `1px solid` };
  return (
    <>
      <Box
        sx={{
          maxHeight: 275,
          maxWidth: 380,
          mx: "auto",
          overflow: "hidden",
          position: "sticky",
          padding: "10px 15px",
        }}
      >
        <Grid item container spacing="24" direction="column">
          <Grid item>
            <Typography variant="subtitle1" alignSelf="start">
              Pago
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          maxHeight: 275,
          maxWidth: 380,
          mx: "auto",
          overflow: "hidden",
          position: "sticky",
          padding: "0 15px 0 15px",
        }}
      >
        <CardSvgStyled />
        <Grid
          container
          flexDirection="column"
          sx={{ position: "absolute", left: 32, top: 144.5 }}
        >
          <Grid item>
            <Typography
              sx={{
                fontWeight: 400,
                fontSize: 35,
                lineHeight: 1.25,
                letterSpacing: -0.24,
                color: "white",
              }}
            >
              ${capabilityUrlInfoData.amountToSend}
            </Typography>
          </Grid>
          {capabilityUrlInfoData.beneficiaryFullName && (
            <Grid item>
              <Typography
                sx={{
                  color: "rgba(255, 255, 255, 0.5)",
                  fontFamily: "Gilroy",
                  fontSize: 10,
                  fontWeight: 600,
                  letterSpacing: 2,
                  lineHeight: 2,
                }}
              >
                {capabilityUrlInfoData.beneficiaryFullName}
              </Typography>
            </Grid>
          )}
        </Grid>
      </Box>
      <PageLayout pageName="" showBackButtonNav={false}>
        <Box
          component="form"
          noValidate
          autoComplete="off"
          sx={{ pl: 1, pr: 1 }}
        >
          <Grid container rowSpacing="64" direction="column">
            <Grid item container spacing="24" direction="column">
              <Grid item alignSelf="start">
                <Typography variant="subtitle1">
                  Información Personal
                </Typography>
              </Grid>
              <Grid item container spacing="17">
                <Grid item sx={{ flex: 1 }}>
                  <TextField
                    label="Nombre"
                    name="firstName"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.firstName}
                    error={Boolean(
                      formik.errors.firstName && formik.touched.firstName
                    )}
                    helperText={
                      formik.touched.firstName && formik.errors.firstName
                    }
                  />
                </Grid>
                <Grid item sx={{ flex: 1 }}>
                  <TextField
                    label="Segundo nombre (Opcional)"
                    name="middleName"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.middleName}
                    error={Boolean(
                      formik.errors.middleName && formik.touched.middleName
                    )}
                    helperText={
                      formik.touched.middleName && formik.errors.middleName
                    }
                  />
                </Grid>
              </Grid>
              <Grid item>
                <TextField
                  label="Apellido(s)"
                  name="lastName"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.lastName}
                  error={Boolean(
                    formik.errors.lastName && formik.touched.lastName
                  )}
                  helperText={formik.touched.lastName && formik.errors.lastName}
                />
              </Grid>
              <Grid item>
                <DatePicker
                  disableFuture
                  defaultCalendarMonth={maxDate}
                  id="birthDate"
                  inputFormat="MM/dd/yyyy"
                  label="Fecha de nacimiento"
                  mask="__/__/____"
                  maxDate={maxDate}
                  name="birthDate"
                  onChange={(value: Date | null) => {
                    formik.setFieldValue("birthDate", value);
                  }}
                  value={formik.values.birthDate}
                  error={Boolean(
                    formik.errors.birthDate && formik.touched.birthDate
                  )}
                />
              </Grid>
            </Grid>
            <Grid item container spacing="24" direction="column">
              <Grid item alignSelf="start">
                <Typography variant="subtitle1">
                  Direccion Asociada a la Tarjeta
                </Typography>
              </Grid>
              <Grid item>
                <TextField
                  label="Correo electrónico"
                  type="email"
                  name="email"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                  error={Boolean(formik.errors.email && formik.touched.email)}
                  helperText={formik.touched.email && formik.errors.email}
                />
              </Grid>
              <Grid item>
                <TextField
                  label="Dirección"
                  name="address"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.address}
                  error={Boolean(
                    formik.errors.address && formik.touched.address
                  )}
                  helperText={formik.touched.address && formik.errors.address}
                />
              </Grid>
              <Grid item>
                <TextField
                  label="Número de Apartamento (Opcional)"
                  name="addressNumber"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.addressNumber}
                  error={Boolean(
                    formik.errors.addressNumber && formik.touched.addressNumber
                  )}
                />
              </Grid>
              <Grid item container spacing="17">
                <Grid item sx={{ flex: 1 }}>
                  <TextField
                    label="Ciudad"
                    name="city"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.city}
                    error={Boolean(formik.errors.city && formik.touched.city)}
                    helperText={formik.touched.city && formik.errors.city}
                  />
                </Grid>
                <Grid item sx={{ flex: 1 }}>
                  <NumberFormat
                    customInput={TextField}
                    format="#####"
                    label="Codigo Postal"
                    name="postalCode"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    type="tel"
                    value={formik.values.postalCode}
                    error={Boolean(
                      formik.errors.postalCode && formik.touched.postalCode
                    )}
                    helperText={
                      formik.touched.postalCode && formik.errors.postalCode
                    }
                  />
                </Grid>
              </Grid>
              <Grid item>
                <Select
                  id="state"
                  label="Estado"
                  labelId="state-label"
                  name="state"
                  onChange={(e) => {
                    formik.setFieldValue("state", e.target.value);
                    validateStateOnCKO(e.target.value as string);
                  }}
                  onBlur={formik.handleBlur}
                  value={formik.values.state}
                >
                  <MenuItem disabled={true} style={useStyle}>
                    United States
                  </MenuItem>
                  {states.map(({ name, abbreviation }) => (
                    <MenuItem key={name} value={abbreviation}>
                      {name}
                    </MenuItem>
                  ))}
                  <MenuItem divider={true} disabled={true} style={useStyle}>
                    Mexico
                  </MenuItem>
                  {mexicanStates.map(({ name, abbreviation }) => (
                    <MenuItem key={name} value={abbreviation}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
            </Grid>
            <CKOCardScreen formik={formik}></CKOCardScreen>
            <Grid item>
              <TermAndConditionsStyled variant="body2">
                Al hacer clic en Pagar, acepto{" "}
                <Link href="https://www.felixpago.com/copia-de-terms-and-conditions">
                  los términos
                </Link>{" "}
                y condiciones y{" "}
                <Link href="https://www.felixpago.com/copia-de-privacy-policy">
                  las políticas de privacidad
                </Link>{" "}
                de Felix. Y entiendo que Félix utiliza Sora ID para la
                verificación de identidad, y estoy de acuerdo con los{" "}
                <Link href="https://www.soraid.com/terms/">
                  términos de uso
                </Link>{" "}
                y la
                <Link href="https://www.soraid.com/privacy/">
                  política de privacidad
                </Link>{" "}
                de Sora ID
              </TermAndConditionsStyled>
            </Grid>
            <SubmitButtonStyled
              fullWidth
              disabled={disableSubmit}
              onClick={() => {
                formik.handleSubmit();
              }}
            >
              {isPaymentLoading && (
                <CircularProgress
                  color="inherit"
                  size={20}
                  thickness={5}
                  sx={{ mr: 1 }}
                />
              )}
              Pagar
            </SubmitButtonStyled>
          </Grid>
        </Box>
      </PageLayout>
    </>
  );
};

export default NewUserStepsPage;
