import { styled } from "@mui/system";

import Typography from "components/Typography";

export const PageNameStyled = styled(Typography)`
  &.MuiTypography-subtitle1 {
    height: 52px;
    line-height: 52px;
  }
`;
