import { Grid, Typography } from "@mui/material";
import Box from "@mui/material/Box";

import Spinner from "components/Spinner";
import { ReactComponent as Logo2 } from "svgs/logo2.svg";

const LoaderScreenRequest = () => {
  return (
    <Box
      sx={{
        width: "100vw",
        height: `${window.innerHeight}px`,
        backgroundColor: "blue.primar",
        position: "fixed",
        top: 0,

        "& > div:first-of-type": {
          bottom: 0,
          left: 0,
          margin: "auto",
          position: "absolute",
          right: 0,
          top: 0,
        },

        "& > svg": {
          position: "fixed",
          bottom: 49,
          left: 0,
          right: 0,
          margin: "auto",
        },
      }}
    >
      <Spinner />
      <Grid
        container
        flexDirection="column"
        sx={{ position: "absolute", padding: "0 20px", top: 80 }}
      >
        <Grid item>
          <Typography
            sx={{
              fontWeight: 400,
              fontSize: 30,
              lineHeight: 1.25,
              letterSpacing: -0.24,
              color: "white",
            }}
          >
            {"Estamos procesando tus datos."}
          </Typography>
        </Grid>
        <Grid item>
          <Typography
            sx={{
              fontWeight: 400,
              fontSize: 30,
              lineHeight: 1.25,
              letterSpacing: -0.24,
              color: "white",
            }}
          >
            {"Gracias por la espera."}
          </Typography>
        </Grid>
      </Grid>
      <Logo2 />
    </Box>
  );
};

export default LoaderScreenRequest;
