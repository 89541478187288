import { default as MuiSelect } from "@mui/material/Select";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import type { SelectProps } from "@mui/material/Select";

import { FormControlStyled } from "./Select.styled";

const Select = ({ label, labelId, children, ...props }: SelectProps) => {
  return (
    <FormControlStyled fullWidth variant="outlined">
      <InputLabel shrink id={labelId}>
        {label}
      </InputLabel>
      <MuiSelect
        {...props}
        label={label}
        labelId={labelId}
        input={<OutlinedInput notched label={label} />}
      >
        {children}
      </MuiSelect>
    </FormControlStyled>
  );
};

export default Select;
