import { Box, Grid, IconButton, InputLabel, Tooltip } from "@mui/material";
import { CKOCardScreenProps } from "./CKOCardScreen.types";
import { Frames, CardNumber, ExpiryDate, Cvv } from "frames-react";
import { useState } from "react";
import { validateCKOElement } from "utils/ckoFrameValidator";
import InfoIcon from "@mui/icons-material/Info";
import TextField from "components/TextField";
import Typography from "components/Typography";
import { ThreeCircles } from "react-loader-spinner";
import statesData from "data/states.json";

const CKO_KEY = process.env.REACT_APP_CKO_KEY ?? "";

const whichCountry = (state: string) => {
  if (state in statesData) {
    return "US";
  }
  return "MX";
};

const CKOCardScreen = ({ formik }: CKOCardScreenProps) => {
  const [showCvvTooltip, setShowCvvTooltip] = useState(false);
  const [showLoader, setShowLoader] = useState(true);
  return (
    <Grid item container spacing="24" direction="column">
      <Grid item alignSelf="start">
        <Typography variant="subtitle1">Información de la Tarjeta</Typography>
      </Grid>
      {showLoader && (
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
          style={{ minHeight: "10vh" }}
        >
          <Grid item>
            <ThreeCircles
              height="100"
              width="100"
              color="#213094"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
              ariaLabel="three-circles-rotating"
              outerCircleColor=""
              innerCircleColor=""
              middleCircleColor=""
            />
          </Grid>
        </Grid>
      )}
      <>
        <Grid item>
          <InputLabel htmlFor="component-simple">Nombre del titular</InputLabel>
          <TextField
            InputLabelProps={{ shrink: true }}
            name="cardHolderName"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.cardHolderName}
            error={Boolean(
              formik.errors.cardHolderName && formik.touched.cardHolderName
            )}
            helperText={
              formik.touched.cardHolderName && formik.errors.cardHolderName
            }
          />
        </Grid>
        <Grid item>
          <Frames
            config={{
              debug: false,
              publicKey: CKO_KEY,
              localization: {
                cardNumberPlaceholder: "Número de tarjeta",
                expiryMonthPlaceholder: "MM",
                expiryYearPlaceholder: "YY",
                cvvPlaceholder: "CVV",
              },
              style: {
                base: {
                  border: "1px solid #2d2929",
                  borderRadius: "12px",
                  color: "#21253F",
                  fontFamily: "Gilroy-Regular, Arial",
                  fontSize: "19px",
                  fontWeight: 400,
                  letterSpacing: "1px",
                  lineHeight: "20px",
                  maxHeight: "60px",
                  padding: "16.5px 14px",
                },
                focus: {
                  color: "#21253F",
                },
                placeholder: {
                  base: {
                    color: "#a2a4af",
                    fontFamily: "Arial",
                    fontSize: "19px",
                    fontWeight: 400,
                    letterSpacing: "1px",
                  },
                  focus: {
                    color: "#a2a4af",
                    fontFamily: "Arial",
                    fontSize: "19px",
                    fontWeight: 400,
                    letterSpacing: "1px",
                  },
                },
              },
              cardholder: {
                name: formik.values.cardHolderName,
                billingAddress: {
                  addressLine1: formik.values.address,
                  addressLine2: formik.values.addressNumber,
                  zip: formik.values.postalCode,
                  city: formik.values.city,
                  state: formik.values.state,
                  country: whichCountry(formik.values.state),
                },
              },
            }}
            ready={() => {
              setShowLoader(false);
            }}
            frameActivated={(e) => {}}
            frameFocus={(e) => {}}
            frameBlur={(e) => {
              // console.log(e.element);
            }}
            frameValidationChanged={(e) => {
              formik.setFieldValue(
                "ckoValidation",
                validateCKOElement(formik.values.ckoValidation, e)
              );
            }}
            paymentMethodChanged={(e) => {}}
            cardValidationChanged={(e) => {
              // console.log(e.isElementValid);
            }}
            cardSubmitted={() => {
              // alert(`Card submitted`);
            }}
            cardTokenized={(e) => {
              // console.log(e);
            }}
            cardTokenizationFailed={(e) => {
              // alert(`Failed: ${e.errorCode} - ${e.message}`);
            }}
            cardBinChanged={(e) => {
              // console.log(e.scheme);
            }}
          >
            <InputLabel htmlFor="component-simple">
              Número de tarjeta
            </InputLabel>
            <CardNumber style={{ maxHeight: "80px" }} />
            <InputLabel htmlFor="component-simple">Fecha expiración</InputLabel>
            <ExpiryDate style={{ maxHeight: "80px" }} />
            <InputLabel htmlFor="component-simple">
              {"Código de seguridad"}
              <Tooltip
                arrow
                placement="top"
                open={showCvvTooltip}
                title="Dé la vuelta a su tarjeta y busque los tres dígitos que aparecen junto a su firma"
              >
                <IconButton onClick={() => setShowCvvTooltip(!showCvvTooltip)}>
                  <InfoIcon />
                </IconButton>
              </Tooltip>
            </InputLabel>
            <Cvv style={{ maxHeight: "80px" }} />
          </Frames>
        </Grid>
        <Box sx={{ pl: 3, pt: 1.5 }}>
          <Typography
            sx={{
              color: "gray.secondary",
              fontFamily: "Gilroy",
              fontSize: 14,
              fontWeight: 500,
              lineHeight: 1.5,
              textTransform: "none",
              "&>span": {
                color: "orange.primary",
              },
            }}
          >
            <span>*</span> Recomendamos usar tarjeta de débito, ya que las
            tarjetas de crédito cobran por avance de efectivo.
          </Typography>
        </Box>
      </>
    </Grid>
  );
};

export default CKOCardScreen;
