import { useQuery } from "react-query";

import { getCardsByUserId } from "queries";
import queriesKeys from "queriesKeys";
import useUserInfoQueryByPhoneNumberQuery from "./useUserInfoQueryByPhoneNumberQuery";

const useCardsByUserId = () => {
  const userInfo = useUserInfoQueryByPhoneNumberQuery();

  return useQuery(
    queriesKeys.listCardsByUserId(userInfo.data?.id ?? ""),
    getCardsByUserId,
    {
      cacheTime: 0,
      enabled: !!userInfo.data?.id,
    }
  );
};

export default useCardsByUserId;
