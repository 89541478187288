import { HashRouter as Router, Routes, Route } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import Auth from "auth";
import { NewUserStepsPage, ReturnedUserStepsPage } from "pages";
import ThemeProvider from "theme";
import CheckIfExistsRegisteredCardsValidator from "validators/CheckIfExistsRegisteredCardsValidator";
import { useEffect } from "react";
import { posthogInit } from "services/posthog";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 1000 * 60 * 60 * 24, // 24 hrs
      staleTime: Infinity,
      retry: 0,
      refetchOnWindowFocus: false,
    },
  },
});

const App = () => {
  useEffect(() => {
    posthogInit();
  }, []);
  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Router>
            <Routes>
              <Route
                path="*"
                element={
                  <Auth>
                    <CheckIfExistsRegisteredCardsValidator>
                      <NewUserStepsPage />
                    </CheckIfExistsRegisteredCardsValidator>
                  </Auth>
                }
              />
              <Route
                path="returned-user-steps"
                element={
                  <Auth>
                    <ReturnedUserStepsPage />
                  </Auth>
                }
              />
            </Routes>
          </Router>
        </LocalizationProvider>
      </ThemeProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
};

export default App;
