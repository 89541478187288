import { useLocation } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";

import PageLayout from "components/PageLayout";
import { PaymethodMethodScreenProps } from "./PaymentMethodScreen.types";
import Typography from "components/Typography";
import { isFeatureEnabled } from "services/posthog";

const PaymentMethodScreen = ({
  onPaymentMethodType,
}: PaymethodMethodScreenProps) => {
  const isACHEnabled = isFeatureEnabled("ach_payments");
  const location = useLocation();
  const { pathname, search } = location;
  const pageName = "Método de Pago";

  return (
    <PageLayout
      pageName={pageName}
      showBackButtonNav
      routesNav={{
        prevRoute: {
          pathname,
          search,
        },
      }}
    >
      <Grid item sx={{ pl: 1.125 }}>
        <Typography
          sx={{
            color: "blue.primary",
            fontSize: 20,
            fontWeight: 500,
            lineHeight: 1.25,
            letterSpacing: "-0.24px",
          }}
        >
          Selecciona el nuevo Método de Pago
        </Typography>
      </Grid>
      <Grid container item sx={{ mt: 4 }}>
        <Box
          sx={{
            bgcolor: "rgba(181, 183, 194, 0.1)",
            borderRadius: 3,
            mb: 5.25,
            overflowX: "hidden",
            px: 2,
            width: "100%",
          }}
        >
          <Grid item container alignItems="center" sx={{ py: 1.5 }}>
            <Button
              onClick={() => {
                onPaymentMethodType("card");
              }}
              startIcon={
                <AddIcon
                  sx={{ color: "blue.primary", width: 24, height: 24 }}
                />
              }
              sx={{
                pl: 0.5,
                "&>.MuiButton-startIcon": {
                  mr: 1.25,
                  "&>svg": {
                    transform: "scale(1.15)",
                  },
                },
              }}
            >
              <Typography
                sx={{
                  color: "blue.primary",
                  fontFamily: "Gilroy",
                  fontWeight: 500,
                  letterSpacing: 1,
                  lineHeight: 1.25,
                  textTransform: "none",
                }}
              >
                Agregar tarjeta
              </Typography>
            </Button>
          </Grid>
          {isACHEnabled && (
            <Grid item container alignItems="center" sx={{ py: 1.5 }}>
              <Button
                onClick={() => {
                  onPaymentMethodType("ach");
                }}
                startIcon={
                  <AddIcon
                    sx={{ color: "blue.primary", width: 24, height: 24 }}
                  />
                }
                sx={{
                  pl: 0.5,
                  "&>.MuiButton-startIcon": {
                    mr: 1.25,
                    "&>svg": {
                      transform: "scale(1.15)",
                    },
                  },
                }}
              >
                <Typography
                  sx={{
                    color: "blue.primary",
                    fontFamily: "Gilroy",
                    fontWeight: 500,
                    letterSpacing: 1,
                    lineHeight: 1.25,
                    textTransform: "none",
                  }}
                >
                  Conectar una cuenta de banco (ACH)
                </Typography>
              </Button>
            </Grid>
          )}
        </Box>
      </Grid>
    </PageLayout>
  );
};

export default PaymentMethodScreen;
