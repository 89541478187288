import {
  matchPath,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { FC, PropsWithChildren, useEffect } from "react";

import { PageNameStyled } from "./PageLayout.styled";
import type { PageLayoutProps } from "./PageLayout.types";

const PageLayout: FC<PropsWithChildren<PageLayoutProps>> = ({
  pageName = "",
  showBackButtonNav = true,
  routesNav,
  children,
}) => {
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const lt = searchParams.get("lt");
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (typeof window.riskifiedBeaconLoad === "undefined") {
      return;
    }

    window.riskifiedBeaconLoad(id);
  }, [id]);

  useEffect(() => {
    if (typeof window.RISKX === "undefined") {
      return;
    }

    window.RISKX.go(location.pathname);
  }, [location]);

  const handleOnBackNavigation = () => {
    if (
      typeof routesNav === "undefined" ||
      typeof routesNav.prevRoute === "undefined"
    ) {
      navigate(`/?id=${id}&lt=${lt}`);

      return;
    }

    const prevRoute =
      typeof routesNav.prevRoute === "string"
        ? routesNav.prevRoute
        : routesNav.prevRoute.pathname ?? "/";

    if (matchPath(location.pathname, prevRoute)) {
      navigate(`/?id=${id}&lt=${lt}`);

      return;
    }

    navigate(routesNav.prevRoute, { replace: true });
  };

  return (
    <Box
      sx={{
        height: `${window.innerHeight}px`,
        maxWidth: 767,
        mx: "auto",
        width: "100%",
      }}
    >
      {pageName !== "" && (
        <Box sx={{ height: 52, position: "relative" }}>
          {showBackButtonNav && (
            <Button
              onClick={handleOnBackNavigation}
              sx={{ position: "absolute", height: 52, color: "blue.secondary" }}
            >
              <ChevronLeft />
            </Button>
          )}
          <PageNameStyled variant="subtitle1">{pageName}</PageNameStyled>
        </Box>
      )}
      <Box
        sx={{
          overflowY: "auto",
          pb: 5.25,
          pl: 2,
          pr: 2,
          position: "relative",
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default PageLayout;
