import axios from "axios";
import qs from "qs";
import type { AxiosInstance } from "axios";

class HttpClient {
  private client!: AxiosInstance;

  constructor(baseURL: string, headers: { [key: string]: string } = {}) {
    this.client = axios.create({
      baseURL,
      headers,
      paramsSerializer: (params) => qs.stringify(params, { indices: false }),
      withCredentials: false,
    });

    this.client.defaults.headers.common = {
      "Cache-Control": "no-cache, no-store, must-revalidate",
      Pragma: "no-cache",
      Expires: "0",
    };
  }

  get get() {
    return this.client.get;
  }

  get post() {
    return this.client.post;
  }

  get put() {
    return this.client.put;
  }

  get delete() {
    return this.client.delete;
  }

  get patch() {
    return this.client.patch;
  }

  setAuthHeader(capabilityId: string) {
    const interceptorId = this.client.interceptors.request.use((config) => ({
      ...config,
      withCredentials: false,
      headers: {
        ...config.headers,
        "felix-authentication-header": capabilityId,
      },
    }));

    return () => {
      this.client.interceptors.request.eject(interceptorId);
    };
  }
}

export default HttpClient;
