import Typography from "@mui/material/Typography";
import { styled } from "@mui/system";
import { TypographyProps } from "@mui/material/Typography";

export default styled(Typography)<TypographyProps>`
  &.MuiTypography-subtitle1 {
    color: ${({ theme }) => theme.palette.blue.primary};
    font-size: 18px;
    letter-spacing: -0.24px;
    line-height: 20px;
    text-align: center;
  }

  &.MuiTypography-body2 {
    color: ${({ theme }) => theme.palette.gray.secondary};
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 1px;
    line-height: 150%;
  }
`;
