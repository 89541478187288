const [, , id] =
  window.location.href.match(/\?([^&=]+)=([^&=]+)(?:&([^&=]+)=([^&=]+))*$/) ??
  [];
const queriesKeys = {
  self: [{ scope: "app", context: id }] as const,
  listCapabilityUrlInfo: () => {
    return [{ ...queriesKeys.self[0], entity: "capabilityUrls" }] as const;
  },
  listIpAddress: () => {
    return [{ ...queriesKeys.self[0], entity: "ipAddress" }] as const;
  },
  listPublicKey: () => {
    return [{ ...queriesKeys.self[0], entity: "publicKey" }] as const;
  },
  listUserInfoById: (id: string) => {
    return [{ ...queriesKeys.self[0], entity: "userInfo", id }] as const;
  },
  listUserInfoByPhoneNumber: (phoneNumber: string, countryCode: string) => {
    return [
      { ...queriesKeys.self[0], entity: "userInfo", phoneNumber, countryCode },
    ] as const;
  },
  listCardsByUserId: (userId: string) => {
    return [{ ...queriesKeys.self[0], entity: "cards", userId }] as const;
  },
  listLinkToken: (userId: string) => {
    return [{ ...queriesKeys.self[0], entity: "linkToken", userId }] as const;
  },
  listBankAccountsByUserId: (userId: string) => {
    return [
      { ...queriesKeys.self[0], entity: "bankAccounts", userId },
    ] as const;
  },
};

export default queriesKeys;
