import { useQuery } from "react-query";

import { getUserInfoByPhoneNumber } from "queries";
import queriesKeys from "queriesKeys";
import useCapabilityUrlInfoQuery from "./useCapabilityUrlInfoQuery";

const useUserInfoQueryByPhoneNumberQuery = () => {
  const capabilityUrlInfo = useCapabilityUrlInfoQuery();
  const userPhone = capabilityUrlInfo?.data?.user_phone ?? "";
  const countryCode = capabilityUrlInfo?.data?.user_phone_country_code ?? "";
  const queryKey = queriesKeys.listUserInfoByPhoneNumber(
    userPhone,
    countryCode
  );

  return useQuery(queryKey, getUserInfoByPhoneNumber, {
    enabled: !!userPhone,
  });
};

export default useUserInfoQueryByPhoneNumberQuery;
