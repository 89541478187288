import { useQuery } from "react-query";

import { getUserInfoById } from "queries";
import queriesKeys from "queriesKeys";
import useUserInfoQueryByPhoneNumberQuery from "./useUserInfoQueryByPhoneNumberQuery";

const useUserInfoQueryById = () => {
  const userInfo = useUserInfoQueryByPhoneNumberQuery();

  return useQuery(
    queriesKeys.listUserInfoById(userInfo.data?.id ?? ""),
    getUserInfoById,
    {
      enabled: !!userInfo.data?.id,
    }
  );
};

export default useUserInfoQueryById;
