import { useCallback, useEffect, useRef, useState } from "react";

const useFingerPrintData = () => {
  const [seon, setSeon] = useState<any>();
  const scriptLoaded = useRef(false);

  useEffect(() => {
    if (typeof window.seon === "undefined" || scriptLoaded.current) {
      return;
    }

    scriptLoaded.current = true;

    setSeon(window.seon);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.seon]);

  const config = useCallback(
    (sessionId: string) => {
      if (typeof seon === "undefined") {
        return;
      }

      return new Promise((resolve, reject) => {
        seon.config({
          host: "seondf.com",
          session_id: sessionId,
          audio_fingerprint: true,
          canvas_fingerprint: true,
          webgl_fingerprint: true,
          onSuccess() {
            resolve(true);
          },
          onError() {
            reject();
          },
        });
      });
    },
    [seon]
  );

  const getBase64Session = useCallback(() => {
    if (typeof seon === "undefined") {
      return;
    }

    return new Promise((resolve, reject) => {
      seon.getBase64Session((data: any) => {
        if (typeof data === "undefined" || data === null) {
          return reject();
        }

        return resolve(data);
      });
    });
  }, [seon]);

  return { config, getBase64Session };
};

export default useFingerPrintData;
