import { useCallback, useEffect, useMemo, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import {
  PlaidLinkOptions,
  PlaidLinkOnSuccessMetadata,
  usePlaidLink,
} from "react-plaid-link";
import * as Sentry from "@sentry/react";
import { v4 as uuidv4 } from "uuid";
import AddIcon from "@mui/icons-material/Add";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import InfoIcon from "@mui/icons-material/Info";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import Radio from "@mui/material/Radio";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Tooltip from "@mui/material/Tooltip";
import NumberFormat from "react-number-format";
import type { ChangeEvent } from "react";

import CardScreen from "components/CardScreen";
import LoaderScreen from "components/LoaderScreen";
import PageLayout from "components/PageLayout";
import SuccessfulPaymentScreen from "components/SuccessfulPaymentScreen";
import TextField from "components/TextField";
import Typography from "components/Typography";
import useCapabilityUrlInfoQuery from "hooks/useCapabilityUrlInfoQuery";
import useCardsByUserId from "hooks/useCardsByUserId";
import useFingerPrintData from "hooks/useFingerPrintData";
import useIpAddressQuery from "hooks/useIpAddressQuery";
import usePublicKeyQuery from "hooks/usePublicKeyQuery";
import useUserInfoQueryById from "hooks/useUserInfoQueryById";
import useLinkToken from "hooks/useLinkToken";
import queriesKeys from "queriesKeys";
import * as apiService from "services/api";
import { SESSION_ID } from "utils/constants";
import {
  CardCompressedSvgStyled,
  CloseCardOptionsStyled,
  InfoEndInputAdornment,
  MasterCardSvgLogoStyled,
  SubmitButtonStyled,
  VisaSvgLogoStyled,
} from "./ReturnedUserStepsPage.styled";
import ErrorScreen from "components/ErrorScreen";
import { posthogIdentify, posthogReset } from "services/posthog";
import { useFormik } from "formik";
import validationSchema from "./ReturnedUserStepsPage.schema";
import {
  segmentAnalyticsPage,
  segmentAnalyticsTrack,
} from "services/segmentAnalytics";
import { useNavigate, useSearchParams } from "react-router-dom";
import PaymentMethodScreen from "components/PaymentMethodScreen/PaymentMethodScreen";
import useBankAccountsByUserId from "hooks/useBankAccountsByUserId";

const ReturnedUserStepsPage = () => {
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const lt = searchParams.get("lt");
  const navigate = useNavigate();
  const [showCvvTooltip, setShowCvvTooltip] = useState(false);
  const [cardPageAction, setCardPageAction] = useState<
    "add" | "edit" | "new-payment-method" | undefined
  >();
  const [showMoreCardOptions, setShowMoreCardOptions] = useState(false);
  const [segmentPageViewHasBeenSent, setSegmentPageViewHasBeenSent] =
    useState(false);
  const [paymentStatus, setPaymentStatus] = useState<AsyncStatus>("IDLE");
  const [errorCode, setErrorCode] = useState<string | undefined>();
  const [cardIndex, setCardIndex] = useState(-1);
  const [bankAccountIndex, setBankAccountIndex] = useState(-1);
  const [cvv, setCvv] = useState("");
  const queryClient = useQueryClient();
  const {
    isLoading: capabilityUrlInfoIsLoading,
    data: capabilityUrlInfoData,
    isError: capabilityUrlError,
  } = useCapabilityUrlInfoQuery();
  const { isLoading: publicKeyIsLoading, data: publicKeyData } =
    usePublicKeyQuery();
  const { isLoading: userInfoIsLoading, data: userInfoData } =
    useUserInfoQueryById();
  const { isLoading: cardsIsLoading, data: cardsData } = useCardsByUserId();
  const { data: ipAddressData } = useIpAddressQuery();
  const fingerPrintData = useFingerPrintData();
  const { isLoading: linkTokenIsLoading, data: linkTokenData } = useLinkToken();
  const { isLoading: bankAccountsIsLoading, data: bankAccountsData } =
    useBankAccountsByUserId();
  const polancoCardRegistrationMutation = useMutation(
    apiService.polancoCardRegistration
  );
  const polancoDeleteCardMutation = useMutation(apiService.polancoDeleteCard);
  const polancoPaymentMutation = useMutation(apiService.polancoCardPayment);
  const bankAccountPaymentMutation = useMutation(apiService.bankAccountPayment);
  const createBankAccountMutation = useMutation(apiService.createBankAccount);
  const isPaymentLoading = paymentStatus === "LOADING";
  const isLoading =
    capabilityUrlInfoIsLoading ||
    userInfoIsLoading ||
    cardsIsLoading ||
    publicKeyIsLoading ||
    linkTokenIsLoading ||
    bankAccountsIsLoading;

  useEffect(() => {
    const initialize = async () => {
      await fingerPrintData.config(SESSION_ID);
    };

    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    Sentry.setUser({ id: userInfoData?.id });
    posthogIdentify(
      userInfoData?.id,
      `${userInfoData?.first_name} ${userInfoData?.last_name}`,
      userInfoData?.phone,
      userInfoData?.phone_country_code
    );
  }, [
    userInfoData?.first_name,
    userInfoData?.id,
    userInfoData?.last_name,
    userInfoData?.phone,
    userInfoData?.phone_country_code,
  ]);

  const formik = useFormik<Partial<NewUserCardPaymentValue>>({
    validationSchema,
    initialValues: { cardCvv: "" },
    onSubmit: async (values) => {},
  });

  const capabilityUrlInfo = useMemo(() => {
    const initialState = {
      amountToSend: "0.00",
      beneficiaryFullName: null,
    };

    if (typeof capabilityUrlInfoData === "undefined") {
      return initialState;
    }

    const amountToSend =
      typeof capabilityUrlInfoData?.amount_to_send !== "string"
        ? initialState.amountToSend
        : parseFloat(capabilityUrlInfoData.amount_to_send).toFixed(2);
    const beneficiaryFullName = capabilityUrlInfoData?.beneficiary_full_name
      ? `Para ${capabilityUrlInfoData.beneficiary_full_name}`
      : initialState.beneficiaryFullName;

    return {
      amountToSend,
      beneficiaryFullName,
    };
  }, [capabilityUrlInfoData]);

  const onSuccessPlaid = (
    public_token: string,
    _: PlaidLinkOnSuccessMetadata
  ) => {
    createBankAccountMutation.mutate(
      {
        public_token: public_token,
        user_id: userInfoData?.id ?? "",
      },
      {
        onSuccess() {
          navigate(`/?id=${id}&lt=${lt}`);
          setCardPageAction(undefined);
        },
        onSettled() {
          queryClient.invalidateQueries([
            { ...queriesKeys.self[0], entity: "bankAccounts" },
          ]);
        },
      }
    );
  };

  const config: PlaidLinkOptions = {
    token: linkTokenData?.link_token ?? "",
    onSuccess: onSuccessPlaid,
  };

  const { open: plaidOpen } = usePlaidLink(config);

  const getFullName = useCallback(
    (lastFourDigits: string) => {
      if (
        typeof userInfoData === "undefined" ||
        typeof lastFourDigits === "undefined"
      ) {
        return "";
      }

      const { first_name, last_name } = userInfoData;

      const fullName = `${first_name} ${last_name}`.substring(0, 13);

      return `${fullName} ...${lastFourDigits}`;
    },
    [userInfoData]
  );

  const currentCard = useMemo<Card | null>(() => {
    if (typeof cardsData === "undefined" || !cardsData.length) {
      return null;
    }

    return cardsData[cardIndex];
  }, [cardsData, cardIndex]);

  const currentBankAccount = useMemo<BankAccount | null>(() => {
    if (typeof bankAccountsData === "undefined" || !bankAccountsData.length) {
      return null;
    }

    return bankAccountsData[bankAccountIndex];
  }, [bankAccountsData, bankAccountIndex]);

  const disabledFormSubmission = useMemo(() => {
    if (bankAccountIndex < 0 && cardIndex < 0) {
      return true;
    }

    if (cardIndex >= 0) {
      return (
        typeof cvv === "undefined" ||
        !cvv.trim() ||
        !/^[0-9]{3,4}$/.test(cvv.trim()) ||
        isPaymentLoading
      );
    }

    return isPaymentLoading;
  }, [cardIndex, bankAccountIndex, cvv, isPaymentLoading]);

  const registerNewCard = async (values: Partial<NewUserCardPaymentValue>) => {
    if (typeof publicKeyData?.key === "undefined") {
      return;
    }

    const sessionData = await fingerPrintData.getBase64Session();

    await polancoCardRegistrationMutation.mutateAsync(
      {
        idempotency_key: uuidv4(),
        user_id: userInfoData?.id,
        user_type: "INDIVIDUAL",
        payment_instrument_data: {
          card_token: values.cardToken,
        },
        payment_instrument: "CARD",
        payment_gateway: values?.paymentGateway,
        payment_onboarding_level: "CARD_CONFIRMATION",
        billing_details: {
          name: values.cardHolderName,
          city: values?.city,
          country: "US",
          line1: values?.address,
          number: values?.addressNumber,
          district: values?.state,
          postal_code: values?.postalCode,
          email: values?.email,
        },
        anti_fraud_data_points: {
          ip_address: ipAddressData,
          session_id: sessionData,
        },
        capability_url_id: id,
      },
      {
        onSettled() {
          queryClient.invalidateQueries([
            { ...queriesKeys.self[0], entity: "cards" },
          ]);
        },
      }
    );
  };

  const handleOnRemoveCard = () => {
    if (
      typeof userInfoData?.id === "undefined" ||
      typeof currentCard?.id === "undefined"
    ) {
      return;
    }
    polancoDeleteCardMutation.mutate(
      {
        card_id: currentCard.id,
      },
      {
        onSuccess() {
          setShowMoreCardOptions(false);
        },
        onSettled() {
          queryClient.invalidateQueries([
            { ...queriesKeys.self[0], entity: "cards" },
          ]);
        },
      }
    );
  };

  const handleOnPaymentMethodTypeSelection = (methodType: string) => {
    if (methodType === "card") {
      setCardPageAction("add");
    } else {
      plaidOpen();
    }
  };

  const handleOnCardActionSubmit = async (values: Partial<CardFormValues>) => {
    await registerNewCard(values);
  };

  const handlePaymentMethodSelection = (
    index: number,
    paymentMethod: string
  ) => {
    setCvv("");
    formik.setFieldValue("cardCvv", "");

    if (paymentMethod === "card") {
      setCardIndex(index);
      setBankAccountIndex(-1);
    } else if (paymentMethod === "bankAccount") {
      setCardIndex(-1);
      setBankAccountIndex(index);
    }
  };

  const handleOnPayment = async () => {
    setPaymentStatus("LOADING");

    if (typeof publicKeyData?.key === "undefined") {
      return;
    }

    if (currentCard) {
      await sendCardPayment();
    } else if (currentBankAccount) {
      await sendBankAccountPayment();
    }
  };

  const sendCardPayment = async () => {
    try {
      const sessionData = await fingerPrintData.getBase64Session();
      const { data: cardPaymentData } =
        await polancoPaymentMutation.mutateAsync({
          idempotency_key: uuidv4(),
          user_id: userInfoData?.id ?? "",
          user_type: "INDIVIDUAL",
          payment_instrument_data: {
            card_id: currentCard?.id ?? "",
            cvv: cvv.trim(),
          },
          payment_instrument: "CARD",
          payment_gateway: currentCard?.payment_gateway,
          payment_onboarding_level: "EXISTING_USER",
          anti_fraud_data_points: {
            session_id: sessionData,
            ip_address: ipAddressData,
          },
          verification: "cvv",
          capability_url_id: id,
        });

      const redirect: string = cardPaymentData.redirect ?? "";

      if (cardPaymentData.error) {
        if (
          typeof cardPaymentData.error === "object" &&
          "error_code" in cardPaymentData.error
        ) {
          setErrorCode(cardPaymentData.error.error_code);
          throw new Error(
            `ERROR ON PAYMENT: ${cardPaymentData.error.error_code} -  ${cardPaymentData.error.error_summary}`
          );
        }
        throw new Error(`ERROR ON PAYMENT: ${cardPaymentData.error}`);
      } else if (redirect !== "") {
        setTimeout(() => {
          posthogReset();
          window.location.replace(redirect);
        }, 500);
      } else if (
        cardPaymentData.required_action &&
        cardPaymentData.required_action.redirect_url
      ) {
        setTimeout(() => {
          posthogReset();
          window.location.replace(cardPaymentData.required_action.redirect_url);
        }, 500);
      } else {
        setPaymentStatus("SUCCESS");
        segmentAnalyticsTrack("return_user_card_payment_succeeded", {
          userId: cardPaymentData.user_id,
          amountToSend: cardPaymentData.amount,
        });
      }
    } catch (e) {
      setPaymentStatus("ERROR");
      Sentry.captureException(e);
    }
  };

  const sendBankAccountPayment = async () => {
    if (!currentBankAccount || !capabilityUrlInfoData || !userInfoData) {
      return;
    }

    try {
      const sessionId = await fingerPrintData.getBase64Session();
      const { data: bankAccountPaymentData } =
        await bankAccountPaymentMutation.mutateAsync({
          idempotency_key: uuidv4(),
          capability_url_id: id,
          account_id: currentBankAccount.account_id,
          metadata: {
            session_id: sessionId,
            ip_address: ipAddressData,
            user_agent: window.navigator.userAgent,
            beneficiary_id: capabilityUrlInfoData.beneficiary_id,
            delivery_method_id: capabilityUrlInfoData.delivery_method_id,
          },
          amount: {
            amount: capabilityUrlInfo.amountToSend,
            currency: "USD",
          },
          user: {
            id: userInfoData.id,
            phone: userInfoData.phone,
            phone_country_code: userInfoData.phone_country_code,
            first_name: userInfoData.first_name,
            middle_name: "",
            last_name: userInfoData.last_name,
          },
        });

      if (bankAccountPaymentData.error) {
        setErrorCode(bankAccountPaymentData.error.error_code);
        throw new Error(
          `ERROR ON PAYMENT: ${bankAccountPaymentData.error.error_code} -  ${bankAccountPaymentData.error.error_summary}`
        );
      } else {
        setPaymentStatus("SUCCESS");
        segmentAnalyticsTrack("return_user_bank_account_payment_succeeded", {
          userId: userInfoData.id,
          amountToSend: capabilityUrlInfo.amountToSend,
        });
      }
    } catch (e) {
      setPaymentStatus("ERROR");
      Sentry.captureException(e);
    }
  };

  const renderCardLogo = (cardNetwork: CardsNetworks) => {
    if (cardNetwork === "VISA") {
      return <VisaSvgLogoStyled />;
    }

    return <MasterCardSvgLogoStyled />;
  };

  if (isLoading) {
    return <LoaderScreen />;
  } else {
    if (!segmentPageViewHasBeenSent) {
      segmentAnalyticsPage("returned_user_page", { userId: userInfoData?.id });
      setSegmentPageViewHasBeenSent(true);
    }
  }

  if (paymentStatus === "SUCCESS") {
    return <SuccessfulPaymentScreen />;
  }

  if (paymentStatus === "ERROR" && errorCode !== undefined) {
    return <ErrorScreen variant={errorCode} />;
  }

  if (paymentStatus === "ERROR") {
    return <ErrorScreen variant="processing_payment_failed" />;
  }

  if (capabilityUrlError) {
    return <ErrorScreen variant="invalid_link" />;
  }

  if (cardPageAction) {
    if (cardPageAction === "new-payment-method") {
      return (
        <PaymentMethodScreen
          onPaymentMethodType={handleOnPaymentMethodTypeSelection}
        />
      );
    } else {
      const cardFormData = Object.assign(
        {
          cardExpirationDate: "",
          cardCvv: "",
        },
        cardPageAction === "add" && {
          cardNumber: "",
          cardHolderName: "",
          cardToken: "",
          email: "",
          address: "",
          addressNumber: "",
          city: "",
          postalCode: "",
          state: "",
          ckoValidation: {
            cardNumber: false,
            expiryDate: false,
            cvv: false,
          },
        }
      );

      return (
        <CardScreen
          variant={cardPageAction}
          cardFormData={cardFormData}
          onSubmitting={handleOnCardActionSubmit}
        />
      );
    }
  }

  return (
    <PageLayout pageName="Pagar con Tarjeta" showBackButtonNav={false}>
      <Box
        sx={{
          maxHeight: 275,
          maxWidth: 380,
          mx: "auto",
          overflow: "hidden",
          position: "sticky",
        }}
      >
        <CardCompressedSvgStyled />
        <Grid
          container
          flexDirection="column"
          sx={{ position: "absolute", left: 32, top: 46 }}
        >
          <Grid item>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: 24,
                lineHeight: 1.25,
                letterSpacing: -0.24,
                color: "white",
              }}
            >
              ${capabilityUrlInfo.amountToSend}
            </Typography>
          </Grid>
          {capabilityUrlInfo.beneficiaryFullName && (
            <Grid item>
              <Typography
                sx={{
                  color: "rgba(255, 255, 255, 0.5)",
                  fontFamily: "Gilroy",
                  fontSize: 10,
                  fontWeight: 600,
                  letterSpacing: 2,
                  lineHeight: 2,
                }}
              >
                {capabilityUrlInfo.beneficiaryFullName}
              </Typography>
            </Grid>
          )}
        </Grid>
      </Box>
      <Grid container sx={{ position: "relative", top: -25 }}>
        <Grid item sx={{ pl: 1.125 }}>
          <Typography
            sx={{
              color: "blue.primary",
              fontSize: 20,
              fontWeight: 500,
              lineHeight: 1.25,
              letterSpacing: "-0.24px",
            }}
          >
            Selecciona Método de Pago
          </Typography>
        </Grid>
        <Grid container item sx={{ mt: 4 }}>
          <Box
            sx={{
              bgcolor: "rgba(181, 183, 194, 0.1)",
              borderRadius: 3,
              mb: 5.25,
              overflowX: "hidden",
              px: 2,
              width: "100%",
            }}
          >
            {cardsData?.map((card, index) => (
              <Grid
                key={card.id}
                container
                alignItems="center"
                sx={{
                  borderBottom: 1,
                  py: 1.5,
                  borderColor: "rgba(2, 20, 132, 0.2)",
                }}
              >
                <Grid item>
                  <Radio
                    checked={cardIndex === index}
                    checkedIcon={<CheckCircleIcon />}
                    onChange={() => {
                      handlePaymentMethodSelection(index, "card");
                    }}
                    sx={{
                      color: "blue.primary",
                      p: 0,
                      pl: 0.25,
                      mr: 1,
                      "&.Mui-checked": {
                        color: "blue.primary",
                      },
                    }}
                  />
                </Grid>
                <Grid item sx={{ display: "flex", width: 45 }}>
                  {renderCardLogo(card.network)}
                </Grid>
                <Grid item sx={{ flex: 1 }}>
                  <Typography
                    sx={{
                      color: "blue.primary",
                      fontFamily: "Gilroy",
                      fontSize: 16,
                      fontWeight: 400,
                      letterSpacing: 1,
                      lineHeight: 1.25,
                      textAlign: "left",
                    }}
                  >
                    {getFullName(card.last_four_digits.toString())}
                  </Typography>
                </Grid>
                <Grid
                  item
                  sx={{
                    alignSelf: "flex-end",
                    display: "flex",
                    width: 24,
                  }}
                >
                  <Button
                    variant="text"
                    onClick={() => {
                      setShowMoreCardOptions(true);
                    }}
                    sx={{ color: "rgba(2, 20, 132, 0.2)", display: "contents" }}
                  >
                    <MoreHorizIcon />
                  </Button>
                </Grid>
              </Grid>
            ))}
            {bankAccountsData?.map((bankAccount, index) => (
              <Grid
                key={bankAccount.account_id}
                container
                alignItems="center"
                sx={{
                  borderBottom: 1,
                  py: 1.5,
                  borderColor: "rgba(2, 20, 132, 0.2)",
                }}
              >
                <Grid item>
                  <Radio
                    checked={bankAccountIndex === index}
                    checkedIcon={<CheckCircleIcon />}
                    onChange={() => {
                      handlePaymentMethodSelection(index, "bankAccount");
                    }}
                    sx={{
                      color: "blue.primary",
                      p: 0,
                      pl: 0.25,
                      mr: 1,
                      "&.Mui-checked": {
                        color: "blue.primary",
                      },
                    }}
                  />
                </Grid>
                <Grid item sx={{ display: "flex", width: 45 }}>
                  ACH
                </Grid>
                <Grid item sx={{ flex: 1 }}>
                  <Typography
                    sx={{
                      color: "blue.primary",
                      fontFamily: "Gilroy",
                      fontSize: 16,
                      fontWeight: 400,
                      letterSpacing: 1,
                      lineHeight: 1.25,
                      textAlign: "left",
                    }}
                  >
                    {getFullName(bankAccount?.mask ?? "")}
                  </Typography>
                </Grid>
              </Grid>
            ))}
            <Grid item container alignItems="center" sx={{ py: 1.5 }}>
              <Button
                onClick={() => {
                  setCardPageAction("new-payment-method");
                }}
                startIcon={
                  <AddIcon
                    sx={{ color: "blue.primary", width: 24, height: 24 }}
                  />
                }
                sx={{
                  pl: 0.5,
                  "&>.MuiButton-startIcon": {
                    mr: 1.25,
                    "&>svg": {
                      transform: "scale(1.15)",
                    },
                  },
                }}
              >
                <Typography
                  sx={{
                    color: "blue.primary",
                    fontFamily: "Gilroy",
                    fontWeight: 500,
                    letterSpacing: 1,
                    lineHeight: 1.25,
                    textTransform: "none",
                  }}
                >
                  Agregar tarjeta o Cuenta de banco (ACH)
                </Typography>
              </Button>
            </Grid>
          </Box>
          {currentCard && (
            <Grid container direction="column">
              <Grid item sx={{ pl: 1.125 }}>
                <Typography
                  sx={{
                    color: "blue.primary",
                    fontSize: 20,
                    fontWeight: 500,
                    lineHeight: 1.25,
                    letterSpacing: "-0.24px",
                  }}
                >
                  Confirma el código de seguridad de la tarjeta seleccionada
                </Typography>
              </Grid>
              <Grid item sx={{ paddingY: 3 }}>
                <NumberFormat
                  autoFocus={true}
                  required
                  name="cardCvv"
                  type="tel"
                  customInput={TextField}
                  format="####"
                  placeholder="CVV"
                  label="Método de pago."
                  value={formik.values.cardCvv}
                  onFocus={() => {
                    setShowCvvTooltip(true);
                  }}
                  onBlur={(e: any) => {
                    setShowCvvTooltip(false);
                    formik.handleBlur(e);
                  }}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    setCvv(e.target.value);
                    formik.handleChange(e);
                  }}
                  sx={{
                    "& .MuiInputBase-input": {
                      textAlign: "end",
                    },
                  }}
                  InputProps={{
                    startAdornment: (
                      <Grid container alignItems="center">
                        <Grid item sx={{ display: "flex", mr: 1.375 }}>
                          {renderCardLogo(currentCard.network)}
                        </Grid>
                        <Grid item>
                          <Typography
                            sx={{
                              color: "blue.primary",
                              fontFamily: "Gilroy",
                              lineHeight: 1.25,
                              letterSpacing: 1,
                            }}
                          >
                            ...{currentCard.last_four_digits}
                          </Typography>
                        </Grid>
                      </Grid>
                    ),
                    endAdornment: (
                      <InfoEndInputAdornment position="end">
                        <Tooltip
                          arrow
                          disableFocusListener
                          disableHoverListener
                          disableTouchListener
                          open={showCvvTooltip}
                          placement="top"
                          title="Dé la vuelta a su tarjeta y busque los tres dígitos que aparecen junto a su firma"
                        >
                          <InfoIcon />
                        </Tooltip>
                      </InfoEndInputAdornment>
                    ),
                  }}
                  error={Boolean(
                    formik.errors.cardCvv && formik.touched.cardCvv
                  )}
                  helperText={formik.touched.cardCvv && formik.errors.cardCvv}
                />
              </Grid>
            </Grid>
          )}
        </Grid>
        <SubmitButtonStyled
          fullWidth
          disabled={disabledFormSubmission}
          onClick={handleOnPayment}
        >
          {isPaymentLoading && (
            <CircularProgress
              color="inherit"
              size={20}
              thickness={5}
              sx={{ mr: 1 }}
            />
          )}
          Pagar
        </SubmitButtonStyled>
      </Grid>
      <SwipeableDrawer
        anchor="bottom"
        open={showMoreCardOptions}
        sx={{
          "&>.MuiPaper-root": {
            borderTopRightRadius: 20,
            borderTopLeftRadius: 20,
          },
        }}
        onOpen={() => {
          setShowMoreCardOptions(true);
        }}
        onClose={() => {
          setShowMoreCardOptions(false);
        }}
      >
        <Grid
          container
          direction="column"
          sx={{
            px: 4,
            py: 2.5,
          }}
        >
          <Grid
            item
            sx={{
              borderBottom: 1,
              borderColor: "rgba(2, 20, 132, 0.2)",
              pb: 2.5,
              width: "100%",
            }}
          >
            <Typography
              sx={{
                color: "blue.primary",
                fontFamily: "Gilroy",
                fontWeight: 500,
                letterSpacing: 1,
                textAlign: "center",
              }}
            >
              Opciones
            </Typography>
          </Grid>
          <Grid item sx={{ mt: 1.5 }}>
            <Button variant="text" sx={{ pl: 0 }} onClick={handleOnRemoveCard}>
              <Typography
                sx={{
                  color: "orange.primary",
                  fontSize: "23px",
                  fontWeight: 500,
                  lineHeight: 1.25,
                  textTransform: "none",
                }}
              >
                Eliminar tarjeta
              </Typography>
            </Button>
          </Grid>
          <Grid item>
            <Typography
              variant="body2"
              sx={{
                color: "gray.secondary",
                fontFamily: "Gilroy",
                fontWeight: 500,
                letterSpacing: "-0.24px",
              }}
            >
              ¡Advertencia! Una vez eliminada, no es posible deshacerlo.
            </Typography>
          </Grid>
          <Grid item sx={{ alignSelf: "center", mt: 5, mb: 2.75 }}>
            <CloseCardOptionsStyled
              onClick={() => {
                setShowMoreCardOptions(false);
              }}
            >
              Cerrar
            </CloseCardOptionsStyled>
          </Grid>
        </Grid>
      </SwipeableDrawer>
    </PageLayout>
  );
};

export default ReturnedUserStepsPage;
