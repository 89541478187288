import * as Yup from "yup";
import valid from "card-validator";

export default Yup.object().shape({
  cardCvv: Yup.string().test("card-cvv", "CVV inválido.", (value) => {
    const cardCvv = value?.trim() ?? "";

    return valid.cvv(cardCvv, cardCvv?.length < 4 ? 3 : 4).isValid;
  }),
});
