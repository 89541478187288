import { useState } from "react";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import IconButton from "@mui/material/IconButton";
import type { RefAttributes } from "react";
import type { MobileDatePickerProps } from "@mui/x-date-pickers/MobileDatePicker";

import TextField from "components/TextField";
import { ReactComponent as CalendarSvg } from "svgs/calendar.svg";
import { InputAdornmentStyled } from "./DatePicker.styled";

type DatePickerProps<TDate = Date | null> = Omit<
  MobileDatePickerProps<any, TDate>,
  "renderInput"
> &
  RefAttributes<HTMLDivElement> & {
    name: string;
    id: string;
    error: boolean;
  };

const DatePicker = ({ error, ...props }: DatePickerProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOnCloseDialog = () => {
    setIsOpen(false);
  };

  const handleOnOpenDialog = () => {
    setIsOpen(true);
  };

  return (
    <MobileDatePicker
      {...props}
      open={isOpen}
      onClose={handleOnCloseDialog}
      showToolbar={true}
      toolbarTitle="Seleccionar fecha de nacimiento"
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder="MM/DD/AAAA"
          error={error}
          onClick={handleOnOpenDialog}
          InputProps={{
            endAdornment: (
              <InputAdornmentStyled position="end">
                <IconButton onClick={handleOnOpenDialog}>
                  <CalendarSvg />
                </IconButton>
              </InputAdornmentStyled>
            ),
          }}
        />
      )}
    />
  );
};

export default DatePicker;
