import { styled } from "@mui/system";
import Button from "@mui/material/Button";
import InputAdornment from "@mui/material/InputAdornment";
import type { InputAdornmentProps } from "@mui/material/InputAdornment";
import type { ButtonProps } from "@mui/material/Button";

import { ReactComponent as CardCompressedSvg } from "svgs/card-compressed.svg";
import { ReactComponent as MasterCardSvgLogo } from "svgs/mastercard.svg";
import { ReactComponent as VisaSvgLogo } from "svgs/visa.svg";

export const CardCompressedSvgStyled = styled(CardCompressedSvg)`
  overflow: hidden;
  width: 100%;
  transform: scale(1.1);
`;

export const MasterCardSvgLogoStyled = styled(MasterCardSvgLogo)`
  width: 35px;
  height: 24px;
`;

export const VisaSvgLogoStyled = styled(VisaSvgLogo)`
  width: 36.5px;
  height: 24px;
`;

export const InfoEndInputAdornment = styled(
  InputAdornment
)<InputAdornmentProps>`
  svg.MuiSvgIcon-root {
    color: ${({ theme }) => theme.palette.gray.secondary};
  }
`;

export const SubmitButtonStyled = styled(Button)<ButtonProps>`
  &.MuiButton-root {
    background-color: ${({ theme }) => theme.palette.orange.primary};
    border-radius: 102px;
    color: white;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: -0.24px;
    line-height: 20px;
    padding-bottom: 14px;
    padding-top: 14px;
    text-transform: none;
    max-width: calc(767px - 32px);
    margin-top: 10px;

    &.Mui-disabled {
      opacity: 0.7;
    }
  }
`;

export const CloseCardOptionsStyled = styled(Button)`
  background-color: ${({ theme }) => theme.palette.orange.primary};
  border-radius: 102px;
  color: white;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.24px;
  line-height: 20px;
  padding-bottom: 14px;
  width: 95px;
  padding-top: 14px;
  text-transform: none;
`;
