import { useQuery } from "react-query";

import { getCapabilityUrlInfo } from "queries";
import queriesKeys from "queriesKeys";

const useCapabilityUrlInfoQuery = () => {
  return useQuery(queriesKeys.listCapabilityUrlInfo(), getCapabilityUrlInfo);
};

export default useCapabilityUrlInfoQuery;
