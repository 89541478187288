import Box from "@mui/material/Box";

import Spinner from "components/Spinner";
import { ReactComponent as Logo2 } from "svgs/logo2.svg";

const LoaderScreen = () => {
  return (
    <Box
      sx={{
        width: "100vw",
        height: `${window.innerHeight}px`,
        backgroundColor: "blue.primar",
        position: "fixed",
        top: 0,

        "& > div:first-of-type": {
          bottom: 0,
          left: 0,
          margin: "auto",
          position: "absolute",
          right: 0,
          top: 0,
        },

        "& > svg": {
          position: "fixed",
          bottom: 49,
          left: 0,
          right: 0,
          margin: "auto",
        },
      }}
    >
      <Spinner />
      <Logo2 />
    </Box>
  );
};

export default LoaderScreen;
