import posthog from "posthog-js";
import { segmentAnalyticsIdentify } from "services/segmentAnalytics";

const POSTHOG_KEY = process.env.REACT_APP_POSTHOG_KEY ?? "";

export const posthogInit = (): void => {
  posthog.init(POSTHOG_KEY, {
    api_host: "https://app.posthog.com",
    session_recording: {
      maskAllInputs: false,
    },
    bootstrap: {
      featureFlags: {
        checkout_frames: true,
        ach_payments: false,
      },
    },
  });
};

export const posthogIdentify = (
  user_id?: string,
  name?: string,
  phone?: string,
  phone_country_code?: string
): void => {
  posthog.identify(user_id, {
    name: name,
    phone: phone,
    phone_country_code: phone_country_code,
  });
  segmentAnalyticsIdentify(user_id, name, phone, phone_country_code);
};

export const isFeatureEnabled = (key: string): boolean => {
  return posthog.isFeatureEnabled(key);
};

export const posthogReset = (): void => {
  posthog.reset();
};
