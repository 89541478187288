import axios from "axios";
import type { AxiosResponse } from "axios";

const IP_SERVICE = process.env.REACT_APP_IP_SERVICE ?? "";

export const getIpAddressInfo = (): Promise<AxiosResponse<IpAddress>> => {
  return axios.get(IP_SERVICE);
};

export const getFallbackIpAddressInfo = (): Promise<
  AxiosResponse<IpAddress>
> => {
  return axios.get("https://n8n.felixpago.com/webhook/myip");
};
