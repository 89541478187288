import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import {
  createTheme,
  ThemeProvider as MuiThemeProvider,
} from "@mui/material/styles";
import { esES } from "@mui/material/locale";
import type { FC, PropsWithChildren } from "react";

import GilroyOtf from "fonts/Gilroy.otf";
import RaisonneOtf from "fonts/Raisonne.otf";

const theme = createTheme(
  {
    components: {
      MuiCssBaseline: {
        styleOverrides: `
        @font-face {
          font-family: 'Raisonne';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: local('Raisonne'), local('Raisonne'), url(${RaisonneOtf});
        }

        @font-face {
          font-family: 'Gilroy';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: local('Gilroy'), local('Gilroy'), url(${GilroyOtf});
        }
      `,
      },
    },
    palette: {
      blue: {
        dark: "#595F84",
        primar: "#021484",
        primary: "#021484",
        secondary: "#20B4EB",
        yankees: "#21253F",
      },
      gray: {
        secondary: "#444449",
        french: "#B5B7C2",
      },
      orange: {
        primary: "#FC4A1E",
      },
    },
    typography: {
      fontFamily: "Raisonne, Gilroy, Roboto",
    },
  },
  esES
);

const ThemeProvider: FC<PropsWithChildren<{}>> = ({ children }) => {
  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline enableColorScheme />
      <Box sx={{ fontFamily: "Raisonne" }}>{children}</Box>
    </MuiThemeProvider>
  );
};

export default ThemeProvider;
