import { useQuery } from "react-query";

import { getPublicKey } from "queries";
import queriesKeys from "queriesKeys";

const usePublicKeyQuery = () => {
  return useQuery(queriesKeys.listPublicKey(), getPublicKey);
};

export default usePublicKeyQuery;
