import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";

import Typography from "components/Typography";
import { ReactComponent as ErrorIndicatorSvg } from "svgs/error-indicator.svg";
import { WhatsAppLogoStyled } from "./ErrorScreen.styled";
import { segmentAnalyticsPage } from "services/segmentAnalytics";
import { useEffect } from "react";

const ErrorScreen = ({ variant }: { variant: ErrorScreensTypes }) => {
  const isInvalidLink = variant === "invalid_link";
  let errorMessage = "";
  let errorDescription = "";
  let errorActionMessage = "";
  let indicatorTopDistance = "";

  if (isInvalidLink) {
    errorMessage = "Liga que ingresaste no es válida.";
    errorDescription =
      "Una disculpa, parece que la liga que ingresaste no es válida. Por favor da click aquí para hablar con nosotros por Whatsapp y te enviaremos una liga nueva.";
    errorActionMessage = "Volver a WhatsApp";
    indicatorTopDistance = `39%`;
  } else {
    if (variant === "20059") {
      errorMessage = "Lo sentimos tu banco rechazó la transacción.";
      errorDescription =
        "Para continuar con tu envío comunícate con tu banco para que autoricen a Felix Pago, o si recibiste un SMS o email de ellos, sigue sus instrucciones para aprobar la transacción.";
      errorActionMessage = "Volver a WhatsApp";
      indicatorTopDistance = `50%`;
    } else if (variant === "20051") {
      errorMessage =
        "Lo sentimos, tu banco rechazó la transacción por fondos insuficientes.";
      errorDescription =
        "Para continuar con tu envío valida que el saldo de tu tarjeta sea mayor al monto que quieres enviar. Si el saldo de tu tarjeta es suficiente para cubrir tu envío, comunícate con tu banco para que autoricen a Felix Pago.";
      errorActionMessage = "Volver a WhatsApp";
      indicatorTopDistance = `50%`;
    } else {
      errorMessage = "Una disculpa, algo salió mal.";
      errorDescription =
        "Estamos trabajando para corregir el error. Por favor da click aquí para hablar con nosotros por Whatsapp.";
      errorActionMessage = "Volver a WhatsApp";
      indicatorTopDistance = `50%`;
    }
  }

  const handleOnWhatsAppRedirect = () => {
    window.location.replace(
      "https://wa.me/+16693333549?text=Hubo%20un%20error%2C%20%C2%BFme%20ayudan%3F"
    );
  };

  useEffect(() => {
    segmentAnalyticsPage("payment_error_page", {});
  }, []);

  return (
    <Box
      sx={{
        width: "100vw",
        height: `${window.innerHeight}px`,
        backgroundColor: "orange.primary",
        position: "fixed",
        top: 0,
      }}
    >
      <Grid
        container
        justifyContent="center"
        sx={{
          maxWidth: 767,
          mx: "auto",
          "& > svg:first-of-type": {
            left: 0,
            margin: "auto",
            position: "fixed",
            right: 0,
            top: indicatorTopDistance,
            transform: "translateY(calc(-50% - 75px))",
          },
        }}
      >
        <ErrorIndicatorSvg />
        <Box sx={{ position: "absolute", bottom: 66, px: 4, maxWidth: 767 }}>
          <Typography
            sx={{
              color: "white",
              fontFamily: "Raisonne",
              fontSize: 43,
              fontWeight: 500,
              letterSpacing: "-0.24px",
              lineHeight: "125%",
            }}
          >
            {errorMessage}
          </Typography>
          <Typography
            sx={{
              color: "white",
              fontFamily: "Gilroy",
              fontWeight: 500,
              fontSize: "16px",
              lineHeight: "150%",
              letterSpacing: "1px",
            }}
          >
            {errorDescription}
          </Typography>
          <Button
            fullWidth
            variant="outlined"
            sx={{
              color: "white",
              mt: 5,
              borderColor: "white",
              borderRadius: 12.75,
              py: "14px",
            }}
            onClick={handleOnWhatsAppRedirect}
          >
            <WhatsAppLogoStyled />
            <Typography
              sx={{
                fontFamily: "Raisonne",
                fontWeight: 500,
                fontSize: "18px",
                lineHeight: "20px",
                letterSpacing: "-0.24px",
                textTransform: "none",
              }}
            >
              {errorActionMessage}
            </Typography>
          </Button>
        </Box>
      </Grid>
    </Box>
  );
};

export default ErrorScreen;
