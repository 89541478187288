import * as Yup from "yup";
import valid from "card-validator";

export const validationAddCardSchema = Yup.object().shape({
  cardHolderName: Yup.string().test(
    "card-holder-name",
    "Nombre del titular inválido.",
    (value) => valid.cardholderName(value).isValid
  ),
  cardNumber: Yup.string(),
  cardExpirationDate: Yup.string(),
  cardCvv: Yup.string(),
  email: Yup.string()
    .email("Debe ser un correo válido.")
    .required("Email requerido."),
  address: Yup.string().required("Dirección requerida."),
  addressNumber: Yup.string(),
  city: Yup.string().required("Ciudad requerida."),
  postalCode: Yup.string().required("Código postal requerido."),
  state: Yup.string().required("Estado requerido."),
  cardToken: Yup.string(),
  ckoValidation: Yup.object().shape({
    cardNumber: Yup.boolean().required().isTrue(),
    expiryDate: Yup.boolean().required().isTrue(),
    cvv: Yup.boolean().required().isTrue(),
  }),
});

export const validationEditCardSchema = Yup.object().shape({
  cardExpirationDate: Yup.string().test(
    "card-expiration-date",
    "Fecha de expiración inválida.",
    (value) => valid.expirationDate(value).isValid
  ),
  cardCvv: Yup.string().test("card-cvv", "CVV inválido.", (value) => {
    const cardCvv = value?.trim() ?? "";

    return valid.cvv(cardCvv, cardCvv?.length < 4 ? 3 : 4).isValid;
  }),
});
