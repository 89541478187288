import type { AxiosResponse } from "axios";

import HttpClient from "services/httpClient";

const API_URL = process.env.REACT_APP_API_URL ?? "";
const POLANCO_URL = process.env.REACT_APP_POLANCO_URL ?? "";

export const apiHttpClient = new HttpClient(`${API_URL}/api/v1`, {
  "Access-Control-Allow-Origin": "*",
  "Content-Type": "application/json",
  Accept: "application/json",
});

export const polancoHttpClient = new HttpClient(`${POLANCO_URL}/api/v1`, {
  "Access-Control-Allow-Origin": "*",
  "Content-Type": "application/json",
  Accept: "application/json",
});

export const getCapabilityUrlInfo = (): Promise<
  AxiosResponse<CapabilityUrlInfo>
> => {
  return apiHttpClient.get("/users/onboarding_link_information");
};

export const getPublicKey = (): Promise<AxiosResponse<PublicKey>> => {
  return apiHttpClient.get("/cards/public_key");
};

export const getExternalOnboardingSession = (
  data: Pick<OnboardingSession, "verification_level">
): Promise<AxiosResponse<OnboardingSession>> => {
  return apiHttpClient.post("/users/external_onboarding_session", data);
};

export const getUserById = (id: string): Promise<AxiosResponse<UserInfo>> => {
  return apiHttpClient.get(`/users/${id}`);
};

export const getUserByPhoneNumber = (
  phoneNumber: string,
  countryCode: string
): Promise<AxiosResponse<UserInfo>> => {
  return apiHttpClient.get(
    `/users/?phone=${phoneNumber}&phone_country_code=${countryCode}`
  );
};

export const newUserPayment = (
  data: Record<string, any>
): Promise<AxiosResponse<NewUserPaymentResponse>> => {
  return polancoHttpClient.post(`/payments/payment`, data);
};

export const polancoCardRegistration = (
  data: Record<string, any>
): Promise<AxiosResponse<CardRegistration, ErrorResponse>> => {
  return polancoHttpClient.post("/payments/payment", data);
};

export const polancoCardPayment = (
  data: Record<string, any>
): Promise<AxiosResponse<CardPayment, ErrorResponse>> => {
  return polancoHttpClient.post("/payments/payment", data);
};

export const getCardsByUserId = (
  userId: string
): Promise<AxiosResponse<Card[], ErrorResponse>> => {
  return polancoHttpClient.get(`/cards?user_id=${userId}`);
};

export const polancoDeleteCard = (
  data: Record<string, string>
): Promise<AxiosResponse<"", ErrorResponse>> => {
  return polancoHttpClient.delete("/cards", {
    data,
  });
};

export const getLinkToken = (
  userId: string
): Promise<AxiosResponse<LinkToken, ErrorResponse>> => {
  return polancoHttpClient.get(`/ach/link-token?user_id=${userId}`);
};

export const createBankAccount = (
  data: BankAccountCreation
): Promise<AxiosResponse<BankAccount, ErrorResponse>> => {
  return polancoHttpClient.post("/ach", data);
};

export const getBankAccountsByUserId = (
  userId: string
): Promise<AxiosResponse<BankAccount[], ErrorResponse>> => {
  return polancoHttpClient.get(`/ach?user_id=${userId}`);
};

export const bankAccountPayment = (
  data: Record<string, any>
): Promise<AxiosResponse<BankAccountPayment, ErrorResponse>> => {
  return polancoHttpClient.post("/payments/ach", data);
};
