import { useQuery } from "react-query";

import { getBankAccountsByUserId } from "queries";
import queriesKeys from "queriesKeys";
import useUserInfoQueryByPhoneNumberQuery from "./useUserInfoQueryByPhoneNumberQuery";

const useBankAccountsByUserId = () => {
  const userInfo = useUserInfoQueryByPhoneNumberQuery();

  return useQuery(
    queriesKeys.listBankAccountsByUserId(userInfo.data?.id ?? ""),
    getBankAccountsByUserId,
    {
      cacheTime: 0,
      enabled: !!userInfo.data?.id,
    }
  );
};

export default useBankAccountsByUserId;
